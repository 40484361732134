import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../../appStore/actionCreators/Agent/agent_actionCreators";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { useDebouncedCallback } from "use-debounce";
import {
  setTodaysQuotes,
  setYesterdaysQuotes,
  setLastWeeksQuotes,
  setArcheivedQuotes,
  setQuoteDetails,
  setBookingWithQuote,
} from "../../../../src/appStore/actionCreators/myQuotes";
// import {setMyFilesData} from "../../../../src/appStore/actionCreators/myFiles"

// import flightIcon from "../../../Assets/flightIcon.png";
import cad from "../../../Assets/Cad.png";
import flieIcon from "../../../Assets/file Icon.svg";
import calculatorIcon from "../../../Assets/calculator-icon.svg";
import {
  setAgentEmergencyNumber,
  setBookingPaymentPaymentStatus,
  setBookingStatus,
  setBookWithPaymentFlag,
  setCreateBookingData,
  setEquivalentFareValue,
  setPaymentRules,
  setPaymentRulesForCheque,
  setPnrCancellationStatus,
  setRepriceFare,
  setRepriceFareLoader,
  setSsrLoaderFlag,
  setSsrSaveEditFlag,
  setSuccessPrice,
  setFarePaymentAttempt,
  setFarePaymentStatus,
  setSeatPaymentAttempt,
  setSeatPaymentStatus,
  setFopSelection,
  setEmailLanguage,
  setMarkupLoader,
  setFlowFrom,
  setCalcErrors,
  setExistingFareRemarkCheck,
  setTripTypeFlowFrom,
  setRepricePnrRadioButton,
  setMarkupButtonCheck,
  setLandCruiseBookingNumberCheck,
  setDisableSaveButton,
  setCancellationConfirmation,
  setCancellationStatus,
  setCancellationBookingCode,
  setMarkupPopUp,
  setSaveCalculatorFlow,
  setIsRetryRequestError,
  setUpdateBookingStatusPopUp,
  setProviderName,
  setShowCalculator,
  setShowCalculatorMsg
} from "../../../appStore/actionCreators/flightBooking";
import {
  setBrandedStatus,
  setSearchStatus,
} from "../../../appStore/actionCreators/flightBooking";
import {
  setFlyingFrom,  
  setFlyingTo,    
  setTripType,  
  setFlyingFromCity, 
  setFlyingToCity, 
  setShowAgencyInfo,
  setAgencyAgentFieldFlag, 
  setAgencyField, 
  setAgentField, 
  setFareRulesError,
  setAgentList,
  setAdultMarkupValue,
  setChildMarkupValue,
  setInfantMarkupValue,
  setApiRenderFlag,
  setProvideId,
  setBookingItinenary
} from "../../../appStore/actionCreators/searchForm";
import {setCarrierPaidSeatData, setLoadingData, setPrePaidSeatStatus, setSaveSeat, setSeat} from "../../../appStore/actionCreators/itinenary"
import { env } from "../../../appConfig";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  setAgencyGroupId,
  // setOrgId,
  // setUserId,
  setAgencyId,
  setAgencyLocation,
  setAgentName,
  setAgentId,
  setAgentCode,
  setAgencyName,
  setAgentEmailId,
  setAgencyDetails,
  setIternalAgentId, 
  setAgentDetails,
  setAgentOldUserId} from "../../../appStore/actionCreators/agent";
import { getAgency } from "../../../appStore/thunks/getAgency";
import { getAgent } from '../../../appStore/thunks/getAgent';
import ToastMessages from "../../../utils/utility/ToastedMessage";
import {msg} from '../../../utils/utility/MessagesContainer';
import getApiResponse from '../../../services/getApiResponse'
import { useTranslation } from "react-i18next";
import FlightTypeErrorHandling from "./FlightTypeErrorHandling";
import { createFilter } from "react-select/dist/react-select.cjs.prod";
import { setfreeTextFareRules } from "../../../appStore/actionCreators/penaltiesTab";
import canadaFlag from '../../../Assets/canada_flag_icon.png'
import usaFlag from '../../../Assets/usa_flag_icon.png';
import { setMarkupValue, setMarkupCheckFlag, setPaymentStatus } from "../../../appStore/actionCreators/BlockSpace/blockSpace_actionCreator";
import { checkAgencyAgentStatus } from "../../../appStore/thunks/checkAgencyAgentStatus";
import { ISACTIVE, ISAVAILABLE } from "../../../utils/constant";


toast.configure();


// const Input = (props) => <components.Input {...props} isHidden={false} />;

export default function EditableSelect() {
  const showAgencyInfo = useSelector(
    (state) => state.searchForm.showAgencyInfo
  );

  const dispatch = useDispatch();
  const brandedStatus = useSelector(
    (state) => state.flightBooking.brandedStatus
  );

  const departFromCode = useSelector(
    (state) => state.searchForm.departFromCode
  );
  const arriveFromCode = useSelector(
    (state) => state.searchForm.arriveFromCode
  );
  const departFromCity = useSelector(
    (state) => state.searchForm.departFromCity
  );
  const arriveFromCity = useSelector(
    (state) => state.searchForm.arriveFromCity
  );
  const tripTypeFromApi = useSelector(
    (state) => state.searchForm.tripTypeFromApi
  );

  const internalAgentId = useSelector((state) => state.agentProfile.internalAgentId);
  const orgId = useSelector((state) => state.agentProfile.orgId);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const agencyList = useSelector((state) => state.searchForm.agencyList)
  const agentList = useSelector((state) => state.searchForm.agentList)
  const agencyField = useSelector((state) => state.searchForm.agencyField)
  const agentField = useSelector((state) => state.searchForm.agentField)
  const wrapperFrom = useRef();
  const wrapperTo = useRef();
  const location = useLocation(); 
  
  // Redux state management
  // const agentProfileValue = useSelector((state) => state.agt.agent_profile);
  const agencyId = useSelector((state) => state.agentProfile.agencyId);
  const agentId = useSelector((state) => state.agentProfile.agentId);
  // const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
  const isLoading = useSelector((state) => state.itinenaries.loading);
  const agentProfileInputValue = useSelector(
    (state) => state.agt.agent_profile_input
  );

  const token = useSelector((state) => state.initToken && state.initToken.token);
  const flightType = useSelector((state) => state.searchForm.flightType);
  const agencyDetails = useSelector((state) => state.agentProfile.agencyDetails);
  let history = useHistory();
  const selectRef = useRef(null);
  const [checkInput, setInput] = useState({
    noOptionsMsg: "Enter input"
  });
  const [checkInputAgent, setInputAgent] = useState({
    noOptionsMsg: "Enter input"
  });
  const[agencySelctionFlag,setAgencySelectionFlag] = useState(false);
  let [flightTypeOptionCheck,setFlightTypeOptionCheck]= useState(false);
  const [agentFieldDisable,setAgentFieldDisable] = useState(true);



  useEffect(() => {
    if (agentProfileInputValue === "") {
      dispatch(actionCreators.agentEnableData(false));
      dispatch(actionCreators.agentBookingId(""));
      dispatch(actionCreators.agentValue(null));
    }
  }, [agentProfileInputValue]);


  const handleMyFile = async(e) => {
    if(flightType !== "USD" && flightType !== "CAD") {
      setFlightTypeOptionCheck(true)
      return false
    }else{
      setFlightTypeOptionCheck(false)
    }

    dispatch(setfreeTextFareRules([]))
    dispatch(setPaymentRules({}))              
    dispatch(setPaymentRulesForCheque({})) 
    dispatch(setPnrCancellationStatus(""))
    dispatch(setPrePaidSeatStatus(false))
    dispatch(setCreateBookingData({}))
    dispatch(setSsrLoaderFlag(false))
    dispatch(setCarrierPaidSeatData(""))
    dispatch(setSsrSaveEditFlag(true));
    dispatch(setSaveSeat([]))
    dispatch(setRepriceFare(""));
    dispatch(setRepriceFareLoader(false));
    dispatch(setFareRulesError(false))
    dispatch(setFarePaymentAttempt(false))
    dispatch(setFarePaymentStatus(false))
    dispatch(setSeatPaymentAttempt(false))
    dispatch(setSeatPaymentStatus(false))
    dispatch(setMarkupLoader(false));
    dispatch(setFlowFrom(""))
    dispatch(setCalcErrors(""))
    dispatch(setFopSelection([]))
    dispatch(setBookingWithQuote(false));
    dispatch(setTripTypeFlowFrom("MyFiles"))
    dispatch(setMarkupButtonCheck(false))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setEquivalentFareValue({}));
    dispatch(setDisableSaveButton(false))
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))
    dispatch(setMarkupPopUp(false))
    dispatch(setMarkupValue(0))
    dispatch(setMarkupCheckFlag(false))
    dispatch(setPaymentStatus(""))
    dispatch(setSaveCalculatorFlow(false))
    dispatch(setIsRetryRequestError(false))
    dispatch(setUpdateBookingStatusPopUp(false))
    dispatch(setProviderName("Sabre"))
    dispatch(setShowCalculator(true));
    dispatch(setShowCalculatorMsg(''));

  let agencyFlag = false

    agencyFlag = true
    dispatch(setBookingPaymentPaymentStatus(""));
    dispatch(setBookWithPaymentFlag(false));

    if (!isInternalUserAutheticated) {
      let travelBussinessID = agencyDetails?.travelBusinessesId;
      let externalUserID = agencyDetails?.userId
      let result = await dispatch(checkAgencyAgentStatus(travelBussinessID, externalUserID))
      if (result && result.hasOwnProperty('isActive')) {
        if ((result?.isActive == ISAVAILABLE.UNAVAILABLE)) {
          return ToastMessages(t('Agent or agency is not allowed to access any bookings.'))
        }
      } else {
        if (agencyDetails?.entityStatus == ISACTIVE.INACTIVE) {
          return ToastMessages(t('Agent or agency is not allowed to access any bookings.'))
        }
      }
    }
  
    if (history.location.pathname === "/HeaderMyFiles") {
      window.location.reload();
    } else {
      if(agencyFlag){
      history.push("/HeaderMyFiles");
      dispatch(setShowAgencyInfo(true));
      }
    }
  };
  const handleMyQuote = (e) => {

    if(flightType !== "USD" && flightType !== "CAD") {
      setFlightTypeOptionCheck(true)
      return false
    }else{
      setFlightTypeOptionCheck(false)
    }

    let agencyFlag = false

    if(isInternalUserAutheticated){
      if((agencyId === undefined || agencyId === null || agencyId === "") && (agentId === undefined || agentId === null || agentId === "")){
        ToastMessages(t('101'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false

      }
      else if(agencyId === undefined || agencyId === null || agencyId === ""){
        ToastMessages(t('102'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false
      }
      else if(agentId === undefined || agentId === null || agentId === ""){
        ToastMessages(t('103'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false
      }
      else{
        agencyFlag = true
        dispatch(setShowAgencyInfo(true));
      }
     }
     else{
      agencyFlag = true
    }
    dispatch(setMarkupButtonCheck(false))
    dispatch(setTodaysQuotes(undefined));
    dispatch(setYesterdaysQuotes(undefined));
    dispatch(setLastWeeksQuotes(undefined));
    dispatch(setArcheivedQuotes(undefined));
    dispatch(setQuoteDetails(undefined));
    dispatch(setBookingStatus(""))
    dispatch(setSaveSeat([]))
    dispatch(setAgentEmergencyNumber(""));
    dispatch(setEquivalentFareValue({}))
    dispatch(setRepriceFare(""));
    dispatch(setSeat([]));
    dispatch(setFarePaymentAttempt(false))
    dispatch(setFarePaymentStatus(false))
    dispatch(setSeatPaymentAttempt(false))
    dispatch(setSeatPaymentStatus(false))
    dispatch(setFopSelection([]))
    dispatch(setEmailLanguage("English"));
    dispatch(setFlowFrom(""))
    dispatch(setCalcErrors(""))
    dispatch(setTripTypeFlowFrom("MyFiles"))
    dispatch(setfreeTextFareRules([]))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setDisableSaveButton(false))
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))
    dispatch(setMarkupPopUp(false))
    dispatch(setMarkupValue(0))
    dispatch(setMarkupCheckFlag(false))
    dispatch(setPaymentStatus(""))
    dispatch(setSaveCalculatorFlow(false))
    dispatch(setIsRetryRequestError(false))
    dispatch(setUpdateBookingStatusPopUp(false))
    dispatch(setProviderName("Sabre"))
    dispatch(setShowCalculator(true));
    dispatch(setShowCalculatorMsg(''));
    if (history.location.pathname === "/MySavedQuotes") {
      window.location.reload();
    } else {
      if(agencyFlag){
        history.push("/MySavedQuotes");
        dispatch(setShowAgencyInfo(true));
      }
    }
  };

  const handleSearchResults = () => {
    if(flightType !== "USD" && flightType !== "CAD") {
      setFlightTypeOptionCheck(true)
      return false
    }else{
      setFlightTypeOptionCheck(false)
    }
    
    dispatch(setAgencyAgentFieldFlag(true));
    if(token===null){
      dispatch(setLoadingData(false));
    } else{
      dispatch(setLoadingData(true));
    }
    
    dispatch(setSearchStatus("searchTab"));
    dispatch(setfreeTextFareRules([]))
    dispatch(setApiRenderFlag(false));
    dispatch(setShowAgencyInfo(true));
    dispatch(setFlyingFrom(departFromCode));
    dispatch(setFlyingFromCity(departFromCity));
    dispatch(setFlyingToCity(arriveFromCity));
    dispatch(setFlyingTo(arriveFromCode));
    dispatch(setTripType(tripTypeFromApi !== "" ? tripTypeFromApi : tripTypeValue));
    dispatch(setPnrCancellationStatus(""));
    dispatch(setPrePaidSeatStatus(false))
    dispatch(setSaveSeat([]))
    dispatch(setRepriceFare(""));
    dispatch(setFareRulesError(false))
    dispatch(setFarePaymentAttempt(false))
    dispatch(setFarePaymentStatus(false))
    dispatch(setSeatPaymentAttempt(false))
    dispatch(setSeatPaymentStatus(false))
    dispatch(setMarkupLoader(false));
    dispatch(setFlowFrom(""))
    dispatch(setCalcErrors(""))
    dispatch(setBookingWithQuote(false));
    dispatch(setTripTypeFlowFrom("MyFiles"))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setEquivalentFareValue({}));
    dispatch(setDisableSaveButton(false))
    dispatch(setBookingItinenary(""));
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))
    dispatch(setMarkupPopUp(false))
    dispatch(setMarkupValue(0))
    dispatch(setMarkupCheckFlag(false))
    dispatch(setPaymentStatus(""))
    dispatch(setSaveCalculatorFlow(false))
    dispatch(setIsRetryRequestError(false))
    dispatch(setUpdateBookingStatusPopUp(false))
    dispatch(setProviderName("Sabre"))
    dispatch(setShowCalculator(true));
    dispatch(setShowCalculatorMsg(''));
    history.push("/SearchResults");
  };

  const handleCalculator = () => {
    if(flightType !== "USD" && flightType !== "CAD") {
      setFlightTypeOptionCheck(true)
      return false
    }else{
      setFlightTypeOptionCheck(false)
    }
    dispatch(setfreeTextFareRules([]))
    dispatch(setSuccessPrice(false));
    dispatch(setShowAgencyInfo(true));
    dispatch(setFareRulesError(false));
    dispatch(setFarePaymentAttempt(false))
    dispatch(setFarePaymentStatus(false))
    dispatch(setSeatPaymentAttempt(false))
    dispatch(setSeatPaymentStatus(false))
    dispatch(setBookingStatus(""))
    dispatch(setAdultMarkupValue(0))
    dispatch(setChildMarkupValue(0))
    dispatch(setInfantMarkupValue(0))
    dispatch(setExistingFareRemarkCheck(false))
    dispatch(setBookingWithQuote(false));
    dispatch(setRepricePnrRadioButton("LowestFare"))
    dispatch(setMarkupButtonCheck(false))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setEquivalentFareValue({}));
    dispatch(setProvideId(""));
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))
    dispatch(setMarkupPopUp(false))
    dispatch(setMarkupValue(0))
    dispatch(setMarkupCheckFlag(false))
    dispatch(setPaymentStatus(""))
    dispatch(setSaveCalculatorFlow(false))
    dispatch(setIsRetryRequestError(false))
    dispatch(setUpdateBookingStatusPopUp(false))
    dispatch(setProviderName("Sabre"))
    dispatch(setShowCalculator(true));
    dispatch(setShowCalculatorMsg(''));
    let agencyFlag = false
    if(isInternalUserAutheticated){
      if((agencyId === undefined || agencyId === null || agencyId === "") && (agentId === undefined || agentId === null || agentId === "")){
        ToastMessages(t('101'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false

      }
      else if(agencyId === undefined || agencyId === null || agencyId === ""){
        ToastMessages(t('102'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false
      }
      else if(agentId === undefined || agentId === null || agentId === ""){
        ToastMessages(t('103'))
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false
      }
      else{
        agencyFlag = true
        dispatch(setShowAgencyInfo(true));
      }
     }
     else{
      agencyFlag = true
    }

    if (history.location.pathname === "/calculator") {
      //  window.location.reload();
      if (brandedStatus === "FromCalculator") {
        dispatch(setBrandedStatus(""));
      } else {
        dispatch(setBrandedStatus("FromCalculator"));
      }
    } else {
      if(agencyFlag){
        dispatch(setBrandedStatus("FromCalculator"));
        history.push("/calculator");
      }
    }
  };

  const handleBlockSpace = () => {
    let agencyFlag = false
    if(isInternalUserAutheticated){
     
      if(agencyId === undefined || agencyId === null || agencyId === ""){
        ToastMessages(t('102'));
        dispatch(setShowAgencyInfo(true));
        agencyFlag= false
      } else if(agentId === undefined || agentId === null || agentId === ""){
          ToastMessages(t('103'))
          dispatch(setShowAgencyInfo(true));
          agencyFlag= false
        }
      else{
        agencyFlag = true
        dispatch(setShowAgencyInfo(true));
      }


     }
     else{
      agencyFlag = true
    }
    
  
    if (history.location.pathname === "/BlockSpace") {
      window.location.reload();
    } else {
      if(agencyFlag){
        dispatch(setSearchStatus("searchTab"));
    dispatch(setfreeTextFareRules([]))
    dispatch(setApiRenderFlag(false));
    dispatch(setShowAgencyInfo(true));
    dispatch(setFlyingFrom(departFromCode));
    dispatch(setFlyingFromCity(departFromCity));
    dispatch(setFlyingToCity(arriveFromCity));
    dispatch(setFlyingTo(arriveFromCode));
    dispatch(setTripType(tripTypeFromApi !== "" ? tripTypeFromApi : tripTypeValue));
    dispatch(setPnrCancellationStatus(""));
    dispatch(setPrePaidSeatStatus(false))
    dispatch(setSaveSeat([]))
    dispatch(setRepriceFare(""));
    dispatch(setFareRulesError(false))
    dispatch(setFarePaymentAttempt(false))
    dispatch(setFarePaymentStatus(false))
    dispatch(setSeatPaymentAttempt(false))
    dispatch(setSeatPaymentStatus(false))
    dispatch(setMarkupLoader(false));
    dispatch(setFlowFrom(""))
    dispatch(setCalcErrors(""))
    dispatch(setBookingWithQuote(false));
    dispatch(setTripTypeFlowFrom("MyFiles"))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setEquivalentFareValue({}));
    dispatch(setDisableSaveButton(false))
    dispatch(setBookingItinenary(""));
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))
    dispatch(setMarkupPopUp(false))
    dispatch(setMarkupValue(0))
    dispatch(setMarkupCheckFlag(false))
    dispatch(setPaymentStatus(""))
    dispatch(setSaveCalculatorFlow(false))
    dispatch(setIsRetryRequestError(false))
    dispatch(setUpdateBookingStatusPopUp(false))
    dispatch(setProviderName("Sabre"))
    dispatch(setShowCalculator(true));
    dispatch(setShowCalculatorMsg(''));
      history.push("/BlockSpace");
      dispatch(setShowAgencyInfo(true));
      }
    }

  }


  const selectAgency = (value) => {
    if (value.length === 1) {
      setInput({
        showOptions: true,
        noOptionsMsg: "",
      });
    }
    if (value.length >= 1) {
      let filterValue = value.replace(/-/g, "")
      dispatch(getAgency(filterValue))
    }
    dispatch(setBrandedStatus(""));
  };

  // DEBOUNCING FUNCTION FOR AGENCY 
  const debouncedAgency = useDebouncedCallback((value) => {
    selectAgency(value)
  }, 250);


  async function myFunctionForAgency(){
    //Axios payload
    const config = {
      method: "GET",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL +`/travelBusinesses?travelBusinessesId=${agencyId}&orgId=${orgId}&walletRequired=true`,
      redirect: "follow",
    }
    const responseAgency = await getApiResponse(config)
    dispatch(setAgencyDetails(responseAgency))
    dispatch(setAgencyId(responseAgency.travelBusinessesId));
    dispatch(setAgencyGroupId(responseAgency.consortiumId));
    dispatch(setAgentCode(responseAgency.adAgencyCode));
    dispatch(setAgencyName(responseAgency.adTravelAgencyName));        
    dispatch(setAgencyLocation(
      {
        "countryCode" : responseAgency.country,
        "stateCode": responseAgency.provinceOrState
      }
       ))

       if(responseAgency.travelBusinessesId && isInternalUserAutheticated){
        //dispatch(setAgentId(""))
        // dispatch(getAgent(""));
      }
  }

  useEffect(() => {

     if(agencySelctionFlag && agencyId !== null && agencyId !== undefined && agencyId !== "" && isInternalUserAutheticated){
      
      myFunctionForAgency()     
               
      }
      
  }, [agencyId]);

  async function myFunctionForAgent(){
    //Axios payload
    const config = {
      method: "GET",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL +`/users/external?userId=${internalAgentId}&orgId=${orgId}`,
      redirect: "follow",
    }
    const responseAgent = await getApiResponse(config)
    dispatch(setAgentDetails(responseAgent))
    dispatch(setAgentId(responseAgent.username));
    dispatch(setAgentName(responseAgent.firstName+" "+responseAgent.lastName));
    dispatch(setAgentEmailId(responseAgent.email)); 
    dispatch(setAgentOldUserId(responseAgent.oldAgentUserId))
  }

  useEffect(()=> {
   
    if(agencySelctionFlag && agencyId !== null && agencyId !== undefined && agencyId !== "" && isInternalUserAutheticated ){
      myFunctionForAgent()
    }
  },[internalAgentId])

  useEffect(()=>{
    if(agencyField === null || agencyField === undefined){
      sessionStorage.removeItem("agency");
      sessionStorage.removeItem("user");
      dispatch((setAgentList([])));
      setAgentFieldDisable(true)
      dispatch(setAgentField(""));
    }
  },[agencyField])

  useEffect(() => {
    if(agencyField){   
      setAgentFieldDisable(false)
    }
  },[])

  const agentAutoSuggest = (value) => {
      setInputAgent({
        showOptions: true,
        noOptionsMsg: "",
      });
    
    if(value.length > 0){
      dispatch(setAgentId(""))
      dispatch(setAgentDetails({}))
      dispatch(getAgent(value));
      }
  };  
   // DEBOUNCING FUNCTION FOR AGENT
   const debouncedAgent = useDebouncedCallback((value) => {
    agentAutoSuggest(value)
  }, 250);
  
const handleCadIconClick = () => {
  if(isInternalUserAutheticated){
    history.push("/?user=internal");

    // dispatch(setAutoCompleteQuoteFrom([]));
    // dispatch(setModifySearchValue(false))
    // dispatch(setAutoCompleteQuoteTo([]));
    // dispatch(setAutoCompleteFlyFromM([]));
    // dispatch(setAutoCompleteFlyToM([]));
    // dispatch(setAutoCompleteReturnTo([]));
    // dispatch(setAutoCompleteReturnFrom([]));
    // dispatch(setAutoCompleteFlyFrom_B([]));
    // dispatch(setAutoCompleteFlyTo_B([]));
    // dispatch(setAutoCompleteFlyFromM([]));
    // dispatch(setAutoCompleteFlyToM([]));
    // dispatch(setAutoCompleteFlyFrom_0([]));
    // dispatch(setAutoCompleteFlyTo_0([]));
    // dispatch(setAutoCompleteFlyFrom_1([]));
    // dispatch(setAutoCompleteFlyTo_1([]));
    // dispatch(setAutoCompleteFlyFrom_2([]));
    // dispatch(setAutoCompleteFlyTo_2([]));
    // dispatch(setAutoCompleteFlyFrom_3([]));
    // dispatch(setAutoCompleteFlyTo_3([]));
    // dispatch(setAutoCompleteFlyFrom_4([]));
    // dispatch(setAutoCompleteFlyTo_4([]));    
    // dispatch(setMultiCityFlyingFromCountry_4(""));
    // dispatch(setMultiCityFlyingFromCountry_0(""))
    // dispatch(setMultiCityFlyingFromCountry_1(""));
    // dispatch(setMultiCityFlyingFromCountry_2(""));
    // dispatch(setMultiCityFlyingFromCountry_3(""));
    // dispatch(setOpenJawFlyingFromCountry_B(""));
    // dispatch(setSpecificAirlinesBlank(false));    
    // dispatch(setFlyingToModifySearchCode(""));
    // dispatch(setFlyingFromModifySearchCode(""));
    // dispatch(setReturnDateModifySearch(""));
    // dispatch(setDepartDateModifySearch(""));
    // dispatch(setFlyingToModifySearch(""));
    // dispatch(setFlyingFromModifySearch(""));
    // dispatch(setArriveFromCode(""));
    // dispatch(setArriveFromCity(""))
    // dispatch(setDepartFromCode(""));
    // dispatch(setDepartFromCity(""));
    // dispatch(setTripTypeFromApi(""));
    // dispatch(setApiRenderFlag(true));
    // dispatch(setGroupItinenaryTimeCalculator(0));
    // dispatch(setPromotionData(""));
    // dispatch(setTimeCalculator(0));
    // dispatch(setProvideId(""));
    // dispatch(setSegmentArrayLength(""));
    // dispatch(setModifySearchValue(false));
    // dispatch(setMultiCityFlyingFromCity_3(""));
    // dispatch(setMultiCityFlyingToCity_4(""));
    // dispatch(setMultiCityFlyingToCity_3(""));
    // dispatch(setMultiCityFlyingFromCity_4(""));
    // dispatch(setMultiCityFlyingFromCity_2(""));
    // dispatch(setMultiCityFlyingToCity_2(""));
    // dispatch(setMultiCityFlyingFromCity_1(""));
    // dispatch(setMultiCityFlyingToCity_1(""));
    // dispatch(setMultiCityFlyingFromCity_0(""));
    // dispatch(setMultiCityFlyingToCity_0(""));
    // dispatch(flexiDateValue(false));
    // dispatch(setFlyingFrom(""));
    // dispatch(setFlyingFromCountry(""));
    // dispatch(setFlyingTo(""));
    // dispatch(setDepartDate(""));
    // dispatch(setReturnDate(""));
    // dispatch(setPassengerAdult(1));
    // dispatch(setPassengerChildren(0));
    // dispatch(setPassengerInfant(0));
    // dispatch(setCabin("Economy"));
    // dispatch(setAirContract(["Air"]));
    // dispatch(setQualifiers([]));
    // dispatch(setAirlines([]));
    // dispatch(setMaxConnections(2));
    // dispatch(setChangesAllowed(""));
    // dispatch(setAlliance(""));
    // dispatch(setNearbyAirport(false));
    // dispatch(setAtleastFreeBaggage(false));
    // dispatch(setAutoCompleteAirLines([]));
    // dispatch(setCarrierOptions([]));
    // dispatch(setInputFieldCount(0));
    // dispatch(setOpenJawDate_B(""));
    // dispatch(setOpenJawFlyingFrom_B(""));
    // dispatch(setOpenJawFlyingTo_B(""));
    // dispatch(setMultiCityFlyingFrom_4(""));
    // dispatch(setMultiCityFlyingTo_4(""));
    // dispatch(setMultiCityFlyingFrom_0(""));
    // dispatch(setMultiCityFlyingTo_0(""));
    // dispatch(setMultiCityFlyingFrom_1(""));
    // dispatch(setMultiCityFlyingTo_1(""));
    // dispatch(setMultiCityFlyingFrom_2(""));
    // dispatch(setMultiCityFlyingTo_2(""));
    // dispatch(setMultiCityFlyingFrom_3(""));
    // dispatch(setMultiCityFlyingTo_3(""));
    // dispatch(setMultiCityDate_4(""));
    // dispatch(setMultiCityDate_0(""));
    // dispatch(setMultiCityDate_1(""));
    // dispatch(setMultiCityDate_2(""));
    // dispatch(setMultiCityDate_3(""));
    // dispatch(setRequestOptions({}));
    // dispatch(setItinenaryIndex(""));
    // dispatch(setBookingItinenary(""));
    // dispatch(setBookingHeader(""));
    // dispatch(setBookingFareId(""));
    // dispatch(setFlyingFromCity(""));
    // dispatch(setFlyingToCity(""));
    // dispatch(setFlyingFromValidation(""));
    // dispatch(setFlyingToValidation(""));
    // dispatch(setDepartureDateValidation(""));
    // dispatch(setReturnDateValidation(""));
    // dispatch(setIsErrorIncrement(0));
    // dispatch(setIsErrorDecrement(0));
    // dispatch(setIsErrorRoundTrip(false));
    // dispatch(setIsErrorOneWayTrip(false));
    // dispatch(setIsErrorOpenJawTrip(false));
    // dispatch(setIsErrorMultiCityTrip(false));
    // dispatch(setAdultMarkupValue(0));
    // dispatch(setChildMarkupValue(0));
    // dispatch(setInfantMarkupValue(0));
    // dispatch(setOpenJawFlyingFromCity_B(""));
    // dispatch(setOpenJawFlyingToCity_B(""));
    // dispatch(setAgencyAgentFieldFlag(true));
    // dispatch(setShowAgencyInfo(true));
    // dispatch(setCarrierPaidSeatData(""))
    // dispatch(setSaveSeat([]))

    // dispatch(setAgentField(""));
    // dispatch(setAgencyField(""));
    // dispatch(setAgencyList([]));
    // dispatch(setAgentList([]));
    // dispatch(setAgentId(""));
    // dispatch(setAgencyId(""));
    // dispatch(setRepriceFare(""));
    // dispatch(setRepriceFareLoader(false))
    // dispatch(setSeat([]));
    // dispatch(setFarePaymentAttempt(false))
    // dispatch(setFarePaymentStatus(false))
    // dispatch(setSeatPaymentAttempt(false))
    // dispatch(setSeatPaymentStatus(false))
  }else{
    history.push("/?")
  }
}

const handleBackToLogin = () => {
  setFlightTypeOptionCheck(false) 
  // history.push("/")  
 }

const onSuggestionHandler = (item) => {
  dispatch(setAgencyId(item?.value?.travelBusinessesId));
  AgencyDetailsApiCall(item?.value?.travelBusinessesId ,item?.label)
  dispatch(setAgencyField(item))
  setAgencySelectionFlag(true)
  setAgentFieldDisable(false)
  dispatch((setAgentList([])));
  dispatch(setAgentField(""));
  dispatch(setAgentId(""))
  dispatch(setAgentDetails({}))
  dispatch(setBrandedStatus(""));
  dispatch(setAgentName(""));
}

// FUNCTION CALL AFTER SELECTION OF AGENCY
async function AgencyDetailsApiCall(value ,label) {
  let config = env.REACT_APP_PROFILE_ORG_BASE_URL + `/travelBusinesses?travelBusinessesId=${value}&orgId=${orgId}&walletRequired=true`
  if(value !== undefined){
    const response = await getApiResponse(config);
    if (response.length > 0) {
      return null;
     
    } else {
    const data = await response;
    if(data?.message === "No records found."){
    dispatch(setAgencyField(null))
    } else {        
      let obj = {}
         obj = {
           label:label,
           value:data
         }
         dispatch(setAgencyField(obj))
      }
    }
  }

}

const onAgentSuggestionHandler = (item) => {
 dispatch(setAgentId(item?.value?.username));
 dispatch(setIternalAgentId(item?.value?.userId));
 AgentDetailsApiCall(item?.value?.userId ,item?.label)
  dispatch(setAgentField(item))


// if (location.pathname === "/HeaderMyFiles") {  
//   window.location.reload();
// }
// if (location.pathname === "/MySavedQuotes") {
//   window.location.reload();
// }
}

// FUNCTION CALL AFTER SELECTION OF AGENT
async function AgentDetailsApiCall(value ,label) {
  let config = env.REACT_APP_PROFILE_ORG_BASE_URL + `/users/external?userId=${value}&orgId=${orgId}`
  if(value !== undefined){
    const response = await getApiResponse(config);
    if (response.length > 0) {
      return null;
     
    } else {
      const data = await response
      if(data?.message === "No records found."){
     dispatch(setAgencyField(null))
      } else {
       let obj = {}
         obj = {
           label:label,
           value:data
         }
         sessionStorage.setItem("user",data.username);
         const emailLang = data?.languagePreference === "An" || data?.languagePreference === "En"
         ? "English"
         : data?.languagePreference === "Fr"
         ? "French"
         : "English";
         dispatch(setEmailLanguage(emailLang))
         dispatch(setAgentField(obj))
         dispatch(setAgentDetails(data))
         dispatch(setAgentId(data.username));
         dispatch(setAgentName(data.firstName+" "+data.lastName));
         dispatch(setAgentEmailId(data.email));
         dispatch(setAgentOldUserId(data.oldAgentUserId))
        }
    }
  } else {
    dispatch(setAgentField(""));
    dispatch(setAgentId(""))
    dispatch(setAgentDetails({}))
    dispatch(setAgentName(""));

  }

}

const useOutsideAlerterFrom = (ref) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const useOutsideAlerterTo = (ref) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

useOutsideAlerterFrom(wrapperFrom);
useOutsideAlerterTo(wrapperTo);
const getSplitValues = (str = "") => {
  const strLength =  str.split(" - ");
  const lastStr = strLength.splice(strLength.length - 1, 1)
  let completeString = {};
  completeString["key1"] = strLength.join(" - ");
  completeString["key2"] = lastStr;
  return completeString;
}
const formatOptionLabel = ({value = {}, label}) => {
  return<div data-as={label}>{getSplitValues(label).key1} <span style={{opacity: 0, position: "absolute", left:0}}>{getSplitValues(label).key2}</span></div>
}

let showCalculatorFlag = env && env.isProd
const { t } = useTranslation();
  return (
    <>
    <div className="booking-main bd-sticky-navBar">
      <div className="container bookingHeader-wrapper bd-main-container">
        <div className="bookingHeader">
          <div>
            <nav className="navbar navbar-expand-sm bookingHeaderNavbar ">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse show" id="collapsibleNavbar">
                <ul className={t("search_Results") === "Résultats de la recherche"? "navbar-nav MenuFrench":"navbar-nav" } >
                  {flightType !== "USD" ? 
                  <li className="nav-item" onClick={handleCadIconClick}>
                    <img className="img-flight" alt="cad-omg" 
                    src={canadaFlag}/>
                    <span className="flight-USD">{t("Flights")} CAD</span> 
                  </li>
                  :
                  <li className="nav-item" onClick={handleCadIconClick}>
                  <img className="img-flight" alt="cad-omg" src={usaFlag} />
                   <span className="flight-USD">{t("Flights")} USD</span>
                  </li>
                  }
                  <li className="nav-item">
                    <a className="nav-link" onClick={handleSearchResults}>
                      <i className="far fa-paper-plane"></i> {t("search_Results")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={handleMyFile}>
                      {" "}
                      <img className="img-fluid" src={flieIcon} /> {t("My_files")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={handleMyQuote}>
                      {" "}
                      <i className="far fa-heart"></i> {t("My_Saved_Quotes")}
                    </a>
                  </li>
                  {
                  <li className="nav-item">
                    <a className="nav-link" onClick={handleCalculator}>
                      {" "}
                      <img className="img-fluid" src={calculatorIcon} />
                      {t("Calculator")}
                    </a>
                  </li>
                  }
                  {isInternalUserAutheticated && <li className="nav-item">
                    <a className="nav-link" onClick={handleBlockSpace}>
                      {" "}
                      <img className="img-fluid" src={flieIcon} /> {t("Exclusive Flight Deals")}
                    </a>
                  </li>}
                </ul>
              </div>
            </nav>
          </div>
          
        </div>
      </div>

    {flightTypeOptionCheck &&
    <FlightTypeErrorHandling
      flightTypeOptionCheck={flightTypeOptionCheck} 
      handleBackToLogin={handleBackToLogin} 
      />
    }
    </div>
    <div className="booking-main">
    {(isInternalUserAutheticated && (isLoading || showAgencyInfo)) ? (
        <div className="container login-agent-wrapper bd-main-container">
            <div className="row bd-main-row">
              <div className="col-lg-5 bd-main-col">
                <div className="bookingScetion">
                  <div className="login-agent-input">
                  <div >
                    <label className="serach_label">{t('Select_Travel_Agency')}</label>
                  </div>
                   
                   <Select
                      ref={selectRef}
                      value={agencyField}
                      name="prefered Airlines"
                      className="basic-multi-select"                      
                      classNamePrefix="select"
                      onChange={onSuggestionHandler}
                      options={ agencyList }
                      placeholder={t("Select_Agency")}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        NoOptionsMessage: () => null
                      }}
                      onInputChange={debouncedAgency}
                      isDisabled={(location.pathname !== "/") && (location.pathname !== "/HeaderMyFiles") && (location.pathname !== "/MySavedQuotes")  && (location.pathname !== "/SearchResults") && (location.pathname !== "/SearchResultsFlexiDate") && (location.pathname !== "/calculator")}
                      isClearable
                      formatOptionLabel={formatOptionLabel}
                      filterOption={createFilter({ignoreAccents: false})}
                    />

                  </div>
                </div>
              </div>
              <div className="col-lg-5 bd-main-col">
                <div className="bookingScetion">
                  <div className="login-agent-input">
                  <div>
                    <label className="serach_label">{t('Select_Travel_Agent')}</label>
                  </div>

                    <Select
                      name="prefered Airlines"
                      value={agentField}
                      className="basic-multi-select"                      
                      classNamePrefix="select"
                      onChange={onAgentSuggestionHandler}
                      options={agentList}
                      placeholder={t("Select_Agent")}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        NoOptionsMessage: () => null
                      }}
                      onInputChange={debouncedAgent}
                      isDisabled={agentFieldDisable || ((location.pathname !== "/") && (location.pathname !== "/HeaderMyFiles") && (location.pathname !== "/MySavedQuotes")  && (location.pathname !== "/SearchResults") && (location.pathname !== "/SearchResultsFlexiDate") && (location.pathname !== "/calculator"))}
                      isClearable
                    />

                  </div>
                </div>
              </div>
            </div>
        </div>
        ) : null}

    </div>
    </>
  );
}
