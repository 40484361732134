import React, { useState } from "react";
import bags from "../../Assets/bag-icon.svg";
import seats from "../../Assets/seat-icon.svg";

import ItineraryIcon from "../../Assets/Itinerary-Icon.svg";
import moment from "moment";
import DetailsTab from "../../components/searchResult/DetailsTab";
import { useDispatch } from "react-redux";
import BookingSubHead from "./BookingSubHead";
import { useSelector } from "react-redux";
import { getPenaltiesData } from "../../appStore/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../../appStore/thunks/getPenaltiesDataForPNR';
import { timeConvert } from "../../utils/utility/convertmintohours";
import { BookingFrom, BookingHead, BookingStatusCode, FlightLogo, TripType } from "../../utils/constant";
import { diffrenceInDatesCheck, getFlightLogo,splitFromCapital } from "../../utils/utility/commenFunctions";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import { getPenaltiesDataForQuote } from "../../appStore/thunks/getPenaltiesDataForQuote";
import { getFareRulesForPnr } from "../../appStore/thunks/getFareRulesForPnr";
import { getRevisedFareRules } from "../../appStore/thunks/getRevisedFareRules";
import { getCabinName } from "../../utils/utility/getCabinName";
import DuffleClassOfServicePopUp from "../common/duffleClassOfServicePopUp";



// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

function BookingItineryDetails(props) {
  const flyingFromCity = useSelector((state) => state.searchForm.flyingFromCity);
  const flyingToCity = useSelector((state) => state.searchForm.flyingToCity);
  const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  const flyingTo = useSelector((state) => state.searchForm.flyingTo);
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
  const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
  const quoteDetails = useSelector((state) => state.savedQuotes.quoteDetails);

  const openJawFlyingFromCity_B = useSelector((state) => state.searchForm.openJawFlyingFromCity_B);
  const openJawFlyingToCity_B = useSelector((state) => state.searchForm.openJawFlyingToCity_B);
  const openJawFlyingFrom_B = useSelector((state) => state.searchForm.openJawFlyingFrom_B);
  const openJawFlyingTo_B = useSelector((state) => state.searchForm.openJawFlyingTo_B);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabin = useSelector((state) => state.searchForm.cabin);
  
  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
  const lang = useSelector( (state) => state.lang.lang)

  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const bookingItinenariesInitial = useSelector((state) => state.searchForm.bookingItinenary);
  let bookingItinenaries;
  if(props.from === BookingFrom.CONFIRMATION){
    bookingItinenaries = bookingItinenariesInitial?.segments;
  }
  else{
    bookingItinenaries = bookingItinenariesInitial;
  }
  const brandCode = useSelector((state)=>state.fareOptions.brandCode)
  const newPaxFares=brandCode?.paxFares;
  const isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  const saveQuotesPaxFares = quoteDetails && quoteDetails.fareDetails && quoteDetails.fareDetails.itineraryFare && quoteDetails.fareDetails.itineraryFare.paxFares;
  let paxFares =  bookingWithQuote ? saveQuotesPaxFares : bookingItineriesData && bookingItineriesData.paxFares;
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails
  let adultsCount 
  let childCount
  let infantCount

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [flightDIndex, setFlightDIndex] = useState(null);

  const [HideFlight1, setHideFlight1] = useState(false);
  const [HideFlight2, setHideFlight2] = useState(false);
  const [duffleClassOfServicePopUp, setDuffleClassOfServicePopUp] = useState(false);

  const leftSection = props.itinenary && props.itinenary[0];
  const leftSectionObject = leftSection && Object.values(leftSection)[0];
  const OutboundCabin = leftSectionObject && leftSectionObject.cabin;

  const rightSection = props.itinenary && props.itinenary[1];
  const rightSectionObject = rightSection && Object.values(rightSection)[0];
  const inboundCabin = rightSectionObject && rightSectionObject.cabin;

  const flights1 = leftSectionObject && leftSectionObject.flights;
  let classOfService1 = leftSectionObject && leftSectionObject.classOfService
  if(bookingWithQuote){
    classOfService1 = fareBasisDetails
  }

  const flights2 = rightSectionObject && rightSectionObject.flights;
  let classOfService2 = rightSectionObject && rightSectionObject.classOfService
  if(bookingWithQuote){
    classOfService2 = fareBasisDetails
  }

  const detailsTabLeft = leftSection && Object.values(leftSection);
  const detailsTabRight = rightSection && Object.values(rightSection);

  const leftSectionLayovers = leftSectionObject && leftSectionObject?.layovers;
  const leftSectionFlightDuration = leftSectionObject && leftSectionObject.durationMinutes;

  const rightSectionFlightDuration = rightSectionObject && rightSectionObject.durationMinutes;
  const rightSectionLayovers = rightSectionObject && rightSectionObject?.layovers;

  let pnrStatus = bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code

  var flightIds = [];
  var flightNamesLeft = [];
  var flightNamesRight = [];
  const baggageDataLeft = [];
  const baggageDataRight = [];
  const newBaggageLeft=[];
  const newBaggageRight=[]

  let baggageDepart ;
  let baggageDepartInWeight ;
  let baggageArrive ;
  let baggageArrivInWeight ;
  let seatsDepart = leftSectionObject && leftSectionObject.seatsRemaining;
  let seatsArrive = rightSectionObject && rightSectionObject.seatsRemaining;
  let carryOnBaggageDepart ;
  let carryOnBaggageDepartInWeight ;
  let carryOnBaggageArrive ;
  let carryOnBaggageArrivInWeight ;
  let baggageDepartUnit;
  let baggageArriveUnit;
  
  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }

console.log("paxFares",fareBasisDetails)
  const handleFlightDToggle1 = (i) => {
    setHideFlight1(!HideFlight1);

    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);
    if(props.fareId) {
      if(bookingWithQuote){
        dispatch(getPenaltiesDataForQuote(props.fareId));
      }else{
        // dispatch(getPenaltiesData(props.fareId));
        dispatch(getRevisedFareRules(props.fareId));
      }
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

  const handleFlightDToggle2 = (i) => {
    setHideFlight2(!HideFlight2);

    if (flightDIndex == i) {
      setFlightDIndex(null);
    }
    setFlightDIndex(i);

    if(props.fareId) {
      if(bookingWithQuote){
        dispatch(getPenaltiesDataForQuote(props.fareId));
      }else{
        dispatch(getRevisedFareRules(props.fareId));
        // dispatch(getPenaltiesData(props.fareId));
      }
    }
    else if(props.recordLocator) {  
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };

// This function never called

  // const handleSeatMap = (i) => {
  //   setHideSeatMap("flight_tabs_show  flightModal bg-white");

  //   if (seatMapIndex == i) {
  //     return setSeatMapIndex(null);
  //   }
  //   setSeatMapIndex(i);

  // };

// This function never called

  // const handleFareOptionToggle = (i) => {
  //   if (fareOption == i) {
  //     setFareOption(null);
  //   } else {
  //     setFareOption(i);
  //   }

  //   dispatch(getFareOptions(props.fareId));
  //   setFareProps(props.fareId);
  // };

  var localeData = moment.updateLocale('fr', {
    monthsShort: [
      "Jan", "Fév", "Mars", "Avr", "Mai",
      "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"
    ],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
  });
  var Frmonts = localeData.monthsShort()
  var Frdays = localeData.weekdaysShort();
  if (lang == "en") {
    moment.locale('en')
  }
  if (lang == "fr") {
    moment.locale('fr', Frmonts);
    moment.locale('fr', Frdays);
  }

  let layoverTime = 0;
  let layoverTime1 = 0;
  let cancelledFlightCode = ["HX","UC","UN","NO","WK"]
  const itineraryKeys1 =props?.itinenary?.[0] && props?.itinenary?.[0][Object.keys(props?.itinenary?.[0])?.[0]]
  const itineraryKeys2 = props?.itinenary?.length > 0 && props?.itinenary?.[1] && props?.itinenary?.[1][Object.keys(props?.itinenary[1])?.[0]]
  const flight1 = itineraryKeys1?.flights
  const flight2 = itineraryKeys2?.flights
  const cabinName1 = getCabinName(flight1)
  const cabinName2 = getCabinName(flight2)
  let finalCabinOutboundName = "";
  let finalCabinInboundName = "";
  if(cabinName1 === "Mixed" || OutboundCabin === "Mixed" || OutboundCabin === "Mixed Cabin"){
    finalCabinOutboundName = "Mixed"
  }

  if(cabinName2 === "Mixed" || inboundCabin === "Mixed" || inboundCabin === "Mixed Cabin"){
    finalCabinInboundName = "Mixed"
  }

  function findBaggages(flightData, paxFares) {
    const [{ fareBasisDetails = [] }] = paxFares;
    const findFlightBaggage = fareBasisDetails.find(flight => flight.flightId === flightData.id)
    return findFlightBaggage;
  }

  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION)? <div className="bd-itinerary-box">
        {/* {!props.isCalcultor && <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/> } */}
        <>
        {props.isCalcultor && (!props.itinenary || (props.itinenary && props.itinenary.length === 0) || (flyingFromCity === undefined)) ?               
        <div className="bd-itinerary-sec bd-confirmation-sec">
                      <div className="quotenote mb-3">
                        <div className="col-lg-12 quotenotediv reprice">
                          <div>
                            <span>
                              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            </span>{" "}
                          </div>
                          <div>
                            <p>
                            {t("Unable to display the details.")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
        <div className="bd-itinery-detail">
          <div className="col-12 bd-itinery-box-wrapper">
            <div className="bd-itinery-box-head">
              <div className="bd-ib-head">
                <strong>{t("Outbound")}</strong>
                {flyingFromCity} ({flyingFrom}) - {flyingToCity} ({flyingTo})
                 {OutboundCabin && " | " + t(splitFromCapital(OutboundCabin))}
              </div>
              <div className="bd-ib-total">

                {leftSectionLayovers && leftSectionLayovers.map(value => {
                  layoverTime += value.durationMinutes

                })}
                {leftSectionFlightDuration !== undefined && <> {t("Total Duration:")}{timeConvert(leftSectionFlightDuration)}  </>}

              </div>
            </div>

            <div className="bd-itinery-box">
              {flights1 && flights1.map((flight, index) => {

                let arrivalDate = flight.arrivalDateTime
                let dapartureDate = flight.departureDateTime
                let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);
                
                flightIds.push(flight.id);
                baggageDataLeft.push(flight.baggageDetails);
                var name =
                  flight.departureAirport + "-" + flight.arrivalAirport;
                flightNamesLeft.push(name);
                let carrierCode = flight && flight.carrier
                let flightLogo = getFlightLogo(carrierCode)
                layoverTime = layoverTime + 1;
                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
               let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
               let flightStatusCode = flight?.statusDetail?.code;
               
                const baggageInfo = findBaggages(flights1[0], paxFares)
                baggageDepartInWeight = baggageInfo?.freeBaggage?.weight;
                baggageDepart = baggageInfo?.freeBaggage && baggageInfo.freeBaggage?.quantity;
                carryOnBaggageDepartInWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                carryOnBaggageDepart = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                baggageDepartUnit = baggageInfo?.freeBaggage?.unit;

                let intermediateBaggageData = []
                paxFares.forEach(element => {
                  let fareBasisDetails = element.fareBasisDetails;
                  fareBasisDetails.forEach((elem) => {
                    if (elem.flightId == flight.id) {
                      let obj = {
                        chargedBaggageDetails: elem.chargedBaggage,
                        freeBaggageDetails: [elem.freeBaggage],
                        paxType: element.passengerDetails.type
                      }
                      intermediateBaggageData.push(obj)
                    }
                  })

                });
                newBaggageLeft.push(intermediateBaggageData);
                
                return (
                  <div key={flight.id}>
                    <div className="bd-itinery-item">
                      {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                      <div className="title">
                        <img src={flightLogo} alt="flightLogo" />
                        <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number} {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService}
                         {/* <span className="cancelled_flight"> - {t("Cancelled")}</span> */}
                       </div>
                      :
                       <div className="title">
                         <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier} </span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number}&nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService} 
                       </div>
                    
                       }
                     
                      <div className="flight-from-to">
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.departureAirport}</strong>{" "}
                            {moment.utc(flight.departureDateTime).format("HH:mm")}
                          </div>
                          <span className="date">
                            {moment.utc(flight.departureDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                        <div className="flight-from-to-divider"></div>
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.arrivalAirport}</strong>
                            {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                            {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                          </div>
                          <span className="date">
                            {moment.utc(flight.arrivalDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                      {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC || pnrStatus === BookingStatusCode.PNTC || cancelledFlightCode.includes(flightStatusCode)) ? 
                        <div className="time-from-to">
                           <span className="tktonholdFailed"> {t("Cancelled")}</span>
                        </div>
                        :
                        <div className="time-from-to">
                        {timeConvert(flight.durationMin)}
                        </div>
                       }
                     
                    </div>
                    {leftSectionLayovers && leftSectionLayovers[index] ? (
                      <div className="bd-itinery-layover">
                        <span>
                          {timeConvert(
                            leftSectionLayovers[index].durationMinutes
                          ) + t("Layover in") + flight.arrivalAirport}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="bd-itinery-btns">
                <div className="seatBag">
                  {seatsDepart && <span>
                    <img src={seats} alt="seat" />
                    {seatsDepart}
                  </span>}



                  {(baggageDepartInWeight || baggageDepart) &&
                    <span>
                      <img src={bags} alt="seat" />
                      {(baggageDepart == undefined || baggageDepartInWeight > baggageDepart) ? " " + baggageDepartInWeight + ` ${baggageDepartUnit}` : baggageDepart}

                    </span>
                  }
                </div>
                {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC && pnrStatus !== BookingStatusCode.PNTC &&
                <div>
                  <button
                    onClick={() => {
                      handleFlightDToggle1(1);
                    }}
                  >
                    {t("Flight Details")}
                  </button>
                </div>
                }
              </div>

              <div className={HideFlight1 ? "" : "flight_tabs_none"}>
                <DetailsTab
                  flightdata={detailsTabLeft}
                  baggagefares={newBaggageLeft}
                  flightName={flightNamesLeft}
                  fareId={props.fareId}
                  paxFare={paxFares}
                  from="left"
                  recordLocator={props.recordLocator}
                  baggage={bookingItinenaries && bookingItinenaries[0]&&Object.values(bookingItinenaries[0])[0]}
                  isDuffleFlow={isDuffleFlow}
                  flowFrom={props.from}
                  finalCabinName={finalCabinOutboundName}
                />
              </div>
            </div>
          </div>
          {flights2 && flights2.length > 0 && <div className="col-12 bd-itinery-box-wrapper">
            <div className="bd-itinery-box-head">
               
               {tripTypeValue === TripType.OPENJAW ?
              <div className="bd-ib-head">
                <strong>{t("Inbound")}</strong>
                {openJawFlyingFromCity_B} ({openJawFlyingFrom_B}) - {openJawFlyingToCity_B} ({openJawFlyingTo_B})
                 {inboundCabin && " | " + t(splitFromCapital(inboundCabin))}
              </div>
              :
              <div className="bd-ib-head">
                <strong>{t("Inbound")}</strong>
                {flyingToCity} ({flyingTo}) - {flyingFromCity} ({flyingFrom})
                 {inboundCabin && " | " + t(splitFromCapital(inboundCabin))}
              </div>
               }
              <div className="bd-ib-total">

                {rightSectionLayovers && rightSectionLayovers.map(value => {
                  layoverTime1 += value.durationMinutes
                })}
                 {rightSectionFlightDuration !== undefined && <> {t("Total Duration:")}{timeConvert(rightSectionFlightDuration)}  </>}
                
              </div>
            </div>
            <div className="bd-itinery-box">
              {flights2 && flights2.map((flight, index) => {

                let arrivalDate = flight.arrivalDateTime
                let dapartureDate = flight.departureDateTime
                let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);
                flightIds.push(flight.id);
                baggageDataRight.push(flight.baggageDetails);

                var name =
                  flight.departureAirport + "-" + flight.arrivalAirport;
                flightNamesRight.push(name);
                let carrierCode = flight && flight.carrier
                let flightLogo = getFlightLogo(carrierCode)
                layoverTime1 = layoverTime1 + 1;
                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                let classOfService = flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService
                let flightStatusCode = flight?.statusDetail?.code;

                const baggageInfo = findBaggages(flights2[0], paxFares)
                baggageArrivInWeight = baggageInfo?.freeBaggage?.weight;
                baggageArrive = baggageInfo?.freeBaggage && baggageInfo.freeBaggage?.quantity;
                carryOnBaggageArrivInWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                carryOnBaggageArrive = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                baggageArriveUnit = baggageInfo?.freeBaggage?.unit;

                let intermediateBaggageData = []
                paxFares.forEach(element => {
                  let fareBasisDetails = element.fareBasisDetails;
                  fareBasisDetails.forEach((elem) => {
                    if (elem.flightId == flight.id) {
                      let obj = {
                        chargedBaggageDetails: elem.chargedBaggage,
                        freeBaggageDetails: [elem.freeBaggage],
                        paxType: element.passengerDetails.type
                      }
                      intermediateBaggageData.push(obj)
                    }
                  })

                });
                newBaggageRight.push(intermediateBaggageData);

                return (
                  <div key={flight.id}>
                    <div className="bd-itinery-item">
                    {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                      <div className="title">
                        <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number}&nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService}
                         {/* <span className="cancelled_flight"> - {t("Cancelled")}</span> */}
                       </div>
                      :
                       <div className="title">
                         <img src={flightLogo} alt="flightLogo" />
                         <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">         
                                      <span className="airPort_name">{flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number}&nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService}
                       </div>
                    
                       }

                      <div className="flight-from-to">
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.departureAirport}</strong>{" "}
                            {moment.utc(flight.departureDateTime).format("HH:mm")}
                          </div>
                          <span className="date">
                            {moment.utc(flight.departureDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                        <div className="flight-from-to-divider"></div>
                        <div className="flight-from-to-city">
                          <div className="city">
                            <strong>{flight.arrivalAirport}</strong>{" "}
                            {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                            {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                          </div>
                          <span className="date">
                            {moment.utc(flight.arrivalDateTime).format(
                              "ddd MMM DD, YYYY"
                            )}
                          </span>
                        </div>
                      </div>

                      {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC || pnrStatus === BookingStatusCode.PNTC || cancelledFlightCode.includes(flightStatusCode)) ? 
                        <div className="time-from-to">
                           <span className="tktonholdFailed">{t("Cancelled")}</span>
                        </div>
                        :
                        <div className="time-from-to">
                        {timeConvert(flight.durationMin)}
                        </div>
                       }
                    </div>

                    {rightSectionLayovers && rightSectionLayovers[index] ? (
                      <div className="bd-itinery-layover">
                        <span>

                          {timeConvert(
                            rightSectionLayovers[index].durationMinutes
                          ) + t("Layover in") + flight.arrivalAirport}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="bd-itinery-btns">
                <div className="seatBag">
                  {seatsArrive && <span>
                    <img src={seats} alt="seat" />
                    {seatsArrive}
                  </span>}


                  {(baggageArrivInWeight || baggageArrive) &&
                    <span>
                      <img src={bags} alt="seat" />
                      {(baggageArrive == undefined || baggageArrivInWeight > baggageArrive) ? " " + baggageArrivInWeight + ` ${baggageArriveUnit}` : baggageArrive}
                    </span>
                  }
                </div>
                {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC && pnrStatus !== BookingStatusCode.PNTC &&
                <div>
                  <button
                    data-toggle="collapse"
                    data-target="#flightdetails2"
                    aria-expanded="false"
                    aria-controls="flightdetails2"
                    onClick={() => {
                      handleFlightDToggle2(2);
                    }}
                  >
                    {t("Flight Details")}
                  </button>
                </div>
                }
              </div>
              <div className={HideFlight2 ? "" : "flight_tabs_none"}>
                <DetailsTab
                  flightdata={detailsTabRight}
                  baggagefares={newBaggageRight}
                  flightName={flightNamesRight}
                  fareId={props.fareId}
                  paxFare={paxFares}
                  from="right"
                  recordLocator={props.recordLocator}
                  baggage={bookingItinenaries && bookingItinenaries[1]&&Object.values(bookingItinenaries[1])[0]}
                  isDuffleFlow={isDuffleFlow}
                  flowFrom={props.from}
                  finalCabinName={finalCabinInboundName}
                />
              </div>
            </div>
          </div>}

        </div>
      }
      </>
      </div> :
        <div className="bd-itinerary-sec">

          <BookingSubHead
            title={t(BookingHead.ITINENARY)}
            imgSrc={ItineraryIcon}
          />
          <div className="one_line">
          <ul className="bd-trip-info">
            <li>
              {tripTypeValue === TripType.ROUNDTRIP ? t("RoundTrip") : t("OpenJaw")}
            </li>
            {lang !== "fr" ?
            <li>{cabin && t(splitFromCapital(cabin))} {t("Class")}</li>
            :
            <li>{t("Class")} {cabin && t(splitFromCapital(cabin))} </li>
            }
            <li>
              {adultsCount} {t("Adult")} {childCount} {t("Child")} {infantCount} {t("Infant")}
            </li>
           
          </ul>
          <p> {brandCode.brandName}</p>
          </div>
         
          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} /> */}
            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box-head">
                  <div className="bd-ib-head">
                    <strong>{t("Outbound")}</strong>
                    {flyingFromCity} ({flyingFrom}) - {flyingToCity} ({flyingTo})
                     {OutboundCabin && " | " + t(splitFromCapital(OutboundCabin))}
                  </div>
                  <div className="bd-ib-total">

                    {leftSectionLayovers && leftSectionLayovers.map(value => {
                      layoverTime += value.durationMinutes

                    })}
                    {t("Total Duration:")} {timeConvert(leftSectionFlightDuration)}
                  </div>
                </div>

                <div className="bd-itinery-box">
                  {flights1 && flights1.map((flight, index) => {
                    
                    let arrivalDate = flight.arrivalDateTime
                    let dapartureDate = flight.departureDateTime
                    let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                    flightIds.push(flight.id);
                    baggageDataLeft.push(flight.baggageDetails);
                    var name =
                      flight.departureAirport + "-" + flight.arrivalAirport;
                    flightNamesLeft.push(name);
                    let carrierCode = flight && flight.carrier
                    let flightLogo = getFlightLogo(carrierCode)
                    layoverTime = layoverTime + 1;
                    let flightClassOfservice = classOfService1 && classOfService1.filter(obj=>obj.flightId===flight.id)
                    let classOfService = flight.classOfService ? flight.classOfService : flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService;
                    let finalPaxFares = newPaxFares ? newPaxFares : paxFares;
                    const baggageInfo = findBaggages(flights1[0], finalPaxFares)
                    baggageDepartInWeight = baggageInfo?.freeBaggage?.[0]?.weight;
                    baggageDepart = baggageInfo?.freeBaggage?.[0] && baggageInfo.freeBaggage?.[0]?.quantity;
                    carryOnBaggageDepartInWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                    carryOnBaggageDepart = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                    baggageDepartUnit = baggageInfo?.freeBaggage?.[0]?.unit;

                    let intermediateBaggageData = []
                    finalPaxFares.forEach(element => {
                      let fareBasisDetails = element.fareBasisDetails;
                      fareBasisDetails.forEach((elem) => {
                        if (elem.flightId == flight.id) {
                          let obj = {
                            chargedBaggageDetails: elem.chargedBaggage,
                            freeBaggageDetails: elem.freeBaggage,
                            paxType: element.passengerDetails.type
                          }
                          intermediateBaggageData.push(obj)
                          classOfService = elem?.classOfService;
                        }
                      })

                    });
                    newBaggageLeft.push(intermediateBaggageData);
                    
                    return (
                      <div key={flight.id}>
                        <div className="bd-itinery-item">
                          <div className="title">
                            <img src={flightLogo} alt="flightLogo" />
                            <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
                                      <span className="airPort_name">{flight.carrierName !== undefined ? flight.carrierName : flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier + " "} </span>                                                           
                          </OverlayTrigger>                                                             
                          {flight.number}&nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService}
                          </div>
                          <div className="flight-from-to">
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.departureAirport}</strong>{" "}
                                {moment.utc(flight.departureDateTime).format("HH:mm")}
                              </div>
                              <span className="date">
                                {moment.utc(flight.departureDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flight-from-to-divider"></div>
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.arrivalAirport}</strong>
                                {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                              </div>
                              <span className="date">
                                {moment.utc(flight.arrivalDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="time-from-to">
                            {timeConvert(flight.durationMin)}
                          </div>
                        </div>
                        {leftSectionLayovers && leftSectionLayovers[index] ? (
                          <div className="bd-itinery-layover">
                            <span>

                              {timeConvert(
                                leftSectionLayovers[index].durationMinutes
                              ) + t("Layover in") + flight.arrivalAirport}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      {seatsDepart && <span>
                        <img src={seats} alt="seat" />
                        {seatsDepart}
                      </span>}
                      {(baggageDepart || baggageDepartInWeight) &&
                        <span>
                        <img src={bags} alt="seat" />
                        {(baggageDepart==undefined || baggageDepartInWeight > baggageDepart) ? " " + baggageDepartInWeight + ` ${baggageDepartUnit}` : baggageDepart}
                      </span>}
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          handleFlightDToggle1(1);
                        }}
                      >
                        {t("Flight Details")}
                      </button>
                    </div>
                  </div>

                  <div className={HideFlight1 ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTabLeft}
                      baggagefares={newBaggageLeft}
                      flightName={flightNamesLeft}
                      fareId={props.fareId}
                      paxFare={props.from === "confirmation" ? paxFares : paxFares}
                      from="left"
                      recordLocator={props.recordLocator}
                      baggage={bookingItinenaries && bookingItinenaries[0]&&Object.values(bookingItinenaries[0])[0]}
                      isDuffleFlow={isDuffleFlow}
                      flowFrom={props.from}
                      finalCabinName={finalCabinOutboundName}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box-head">
                  {tripTypeValue === TripType.ROUNDTRIP ?
                    <div className="bd-ib-head">
                      <strong>{t("Inbound")}</strong>
                      {flyingToCity} ({flyingTo}) - {flyingFromCity} ({flyingFrom})
                       {inboundCabin && " | " + t(splitFromCapital(inboundCabin))}
                    </div>
                    :
                    <div>
                      <strong>{t("Inbound")}</strong>
                      {openJawFlyingFromCity_B} ({openJawFlyingFrom_B}) - {openJawFlyingToCity_B} ({openJawFlyingTo_B})
                      {inboundCabin && " | " + t(splitFromCapital(inboundCabin))}
                    </div>
                  }
                  <div className="bd-ib-total">

                    {rightSectionLayovers && rightSectionLayovers.map(value => {
                      layoverTime1 += value.durationMinutes
                    })}
                    {t("Total Duration:")} {timeConvert(rightSectionFlightDuration)}
                  </div>
                </div>
                <div className="bd-itinery-box">
                  {flights2 && flights2.map((flight, index) => {

                    let arrivalDate = flight.arrivalDateTime
                    let dapartureDate = flight.departureDateTime
                    let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate);

                    flightIds.push(flight.id);
                    baggageDataRight.push(flight.baggageDetails);

                    var name =
                      flight.departureAirport + "-" + flight.arrivalAirport;
                    flightNamesRight.push(name);
                    let carrierCode = flight && flight.carrier
                    let flightLogo = getFlightLogo(carrierCode)
                    layoverTime1 = layoverTime1 + 1;
                    let flightClassOfservice = classOfService2 && classOfService2.filter(obj=>obj.flightId===flight.id)
                    let classOfService = flight.classOfService ? flight.classOfService : flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService;
                    let finalPaxFares= newPaxFares?newPaxFares:paxFares
                    const baggageInfo = findBaggages(flights2[0], finalPaxFares)
                    baggageArrivInWeight = baggageInfo?.freeBaggage?.[0]?.weight;
                    baggageArrive = baggageInfo?.freeBaggage?.[0] && baggageInfo.freeBaggage?.[0]?.quantity;
                    carryOnBaggageArrivInWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                    carryOnBaggageArrive = baggageInfo?.carryOnBaggage?.[0] && baggageInfo.carryOnBaggage?.[0]?.quantity;
                    baggageArriveUnit = baggageInfo?.freeBaggage?.[0]?.unit;
                    let intermediateBaggageData = []
                    finalPaxFares.forEach(element => {
                      let fareBasisDetails = element.fareBasisDetails;
                      fareBasisDetails.forEach((elem) => {
                        if (elem.flightId == flight.id) {
                          let obj = {
                            chargedBaggageDetails: elem.chargedBaggage,
                            freeBaggageDetails: elem.freeBaggage,
                            paxType: element.passengerDetails.type
                          }
                          intermediateBaggageData.push(obj)
                          classOfService = elem?.classOfService;
                        }
                      })

                    });
                    newBaggageRight.push(intermediateBaggageData);
                    
                    return (
                      <div key={flight.id}>
                        <div className="bd-itinery-item">
                          <div className="title">
                            <img src={flightLogo} alt="flightLogo" />
                            <OverlayTrigger
                                  trigger="hover"
                                  placement="top"
                                  delay={{ show: 100 }}
                                  overlay={
                                  <Popover className="seatView-popover">
                                      <div className="seatView-popover-row seatView-popover-amount">
          
                                      <span className="airPort_name">{flight.carrierName !== undefined ? flight.carrierName : flight.carrierName}</span>
                                  </div>
                                  </Popover>
                                  }
                                >                              
                                  <span className="bd-carrier-Name">{flight.carrier}</span>                                                           
                          </OverlayTrigger>                                                             
                         {flight.number}&nbsp; {props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i> : classOfService}
                          </div>

                          <div className="flight-from-to">
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.departureAirport}</strong>{" "}
                                {moment.utc(flight.departureDateTime).format("HH:mm")}
                              </div>
                              <span className="date">
                                {moment.utc(flight.departureDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                            <div className="flight-from-to-divider"></div>
                            <div className="flight-from-to-city">
                              <div className="city">
                                <strong>{flight.arrivalAirport}</strong>{" "}
                                {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="sid-change-days">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                                {/* {moment.utc(flight.arrivalDateTime).format("HH:mm")} */}
                              </div>
                              <span className="date">
                                {moment.utc(flight.arrivalDateTime).format(
                                  "ddd MMM DD, YYYY"
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="time-from-to">
                            {timeConvert(flight.durationMin)}
                          </div>
                        </div>

                        {rightSectionLayovers && rightSectionLayovers[index] ? (
                          <div className="bd-itinery-layover">
                            <span>

                              {timeConvert(
                                rightSectionLayovers[index].durationMinutes
                              ) + t("Layover in") + flight.arrivalAirport}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                  <div className="bd-itinery-btns">
                    <div className="seatBag">
                      {seatsArrive && <span>
                        <img src={seats} alt="seat" />
                        {seatsArrive}
                      </span>}


                      {(baggageArrivInWeight || baggageArrive) &&
                        <span>
                          <img src={bags} alt="seat" />
                          {(baggageArrive==undefined || baggageArrivInWeight > baggageArrive) ? " " + baggageArrivInWeight + ` ${baggageArriveUnit}` : baggageArrive}
                      </span>}
                    </div>
                    <div>
                      <button
                        data-toggle="collapse"
                        data-target="#flightdetails2"
                        aria-expanded="false"
                        aria-controls="flightdetails2"
                        onClick={() => {
                          handleFlightDToggle2(2);
                        }}
                      >
                        {t("Flight Details")}
                      </button>
                    </div>
                  </div>
                  <div className={HideFlight2 ? "" : "flight_tabs_none"}>
                    <DetailsTab
                      flightdata={detailsTabRight}
                      baggagefares={newBaggageRight}
                      flightName={flightNamesRight}
                      fareId={props.fareId}
                      paxFare={props.from === "confirmation" ? paxFares : paxFares}
                      from="right"
                      recordLocator={props.recordLocator}
                      baggage={bookingItinenaries && bookingItinenaries[1]&&Object.values(bookingItinenaries[1])[0]}
                      isDuffleFlow={isDuffleFlow}
                      flowFrom={props.from}
                      finalCabinName={finalCabinInboundName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <DuffleClassOfServicePopUp open={duffleClassOfServicePopUp} handleClose={()=>setDuffleClassOfServicePopUp(false)} />
    </>
  );
}

export default BookingItineryDetails;
