// import { setPassengerData } from "../actionCreators/flightBooking";
import {
  SET_SEGMENT,
  SET_ITINARY_FARE,
  SET_PASSENGER_DATA,
  SET_PNR,
  SET_BOOKING_DETAIL,
  SET_TOTAL_SELLING_PRICE,
  SET_BOOKING_PAYMENT_STATUS,
  SET_ITINARY_FARES_TYPE,
  SET_BOOKING_DATE,
  SET_BOOKING_STATUS,
  PRICE_STATUS,
  SET_PNR_CANCELLATION_STATUS,
  SET_CANCELLATION_POPUP,
  SET_PASSENGER_DETAILS_ARRAY,
  SET_BOOK_WITH_PAYMENT_FLAG,
  SET_BRANDED_ITINARY_FARE,
  SET_SEATMAP_HEADER,
  SET_PNR_PRICE,
  SET_BRANDED_STATUS,
  SET_SEARCH_STATUS,
  SET_PAYMENT_RULES,
  SET_MARKUP_STATUS,
  SET_PASSENGER_FRAUD_LIST,
  SET_FRAUD_USER_EXISTS,
  SET_FRAUD_USER_EXISTS_WITH_PAYMENT,
  SET_FRAUD_USER_FLAG,
  SET_ADULT_COUNT,
  SET_CHILDREN_COUNT,
  SET_INFANT_COUNT,
  SET_MYFILE_FLAG,
  SET_PAYMENT_RULES_FOR_CHEQUE,
  SET_CREATE_BOOKING_DATA,
  SET_PRICING_FARE_DATA,
  SET_SSR_LOADER_FLAG,
  SET_SSR_SAVE_EDIT_FLAG,
  SET_APPLY_BUTTON_CLICK_FLAG,
  SET_LAND_CRUISE_BOOKING_NUMBER,
  SET_AGENT_EMERGENCY_NUMBER,
  SET_VALIDATION_ON_LAND_CRUISE,
  SET_EQUIVALENT_FARE_VALUE,
  SET_EQUIVALENT_FARE_LOADER,
  SET_REPRICE_FARE,
  SET_REPRICE_FARE_LOADER,
  SET_FREQUENT_FLYER_DROPDOWN,
  SET_GET_PNR_RESULT,
  SET_BOOKING_STATUS_CODE,
  SET_FARE_PAYMENT_ATTEMPT, 
  SET_SEAT_PAYMENT_ATTEMPT,
  SET_FARE_PAYMENT_STATUS,
  SET_SEAT_PAYMENT_STATUS,
  SET_FOP_SELECTION,
  SET_EMAIL_LANGUAGE,
  SET_MARKUP_LOADER,
  SET_FLOW_FROM,
  SET_CALC_ERRORS,
  SET_EXISTING_FARE_REMARK_CHECK,
  SET_TRIPTYPE_FLOW_FROM,
  SET_REPRICE_PNR_RADIO_BUTTON,
  SET_SELECTED_BRANDED_FARE,
  SET_MARKUP_BUTTON_CHECK,
  SET_MARKUP_BUTTON_COUNT,
  SET_LAND_CRUISE_BOOKING_NUMBER_CHECK,
  SET_DISABLE_SAVE_BUTTON,
  ALLOW_SEAT_PAYMENT,
  OPEN_QUOTE_DIALOG,
  SET_AUTO_REPRICE,
  SET_PAX_TYPE_CODE_ERROR_FLAG,
  SET_CANCELLATION_STATUS,
  SET_CANCELLATION_CONFIRMATION,
  SET_REGULAR_REFUND_CANCELLATION_DETAILS,
  SET_OFFERID_TIMEOUT,
  SET_EXPIRE_TIME,
  SET_SEND_AUTO_EMAIL,
  SET_PASSENGER_INFO_FOR_LATER_PAY,
  SET_APPLY_MARKUP_BUTTON,
  SET_CANCELLATION_BOOKING_CODE,
  SET_SAVE_BUTTON_LOADER,
  SET_APPLY_MARKUP_POPUP,
  SET_INITIAL_ITINERARY_FARE,
  SET_INITIAL_PAYMENT_RULES,
  SET_UNSAVED_CHANGES_FLAG,
  SET_INITIAL_PAYMENT_RULES_CHQ,
  SET_UPDATE_PNR_CALCULATOR_FLOW,
  SET_ENABLE_BRANDED_FARES,
  SET_ENABLE_PER_PASSENGER,
  SET_OUTBOUND_BRAND_CODES,
  SET_OUTBOUND_BRAND_CODE_PER_PASSENGERS,
  SET_INBOUND_BRAND_CODES,
  SET_INBOUND_BRAND_CODE_PER_PASSENGERS,
  SET_VALIDATING_CARRIER,
  SET_BRANDCODE_OUT_DROPDOWN,
  SET_SAVE_CALCULATOR_FLOW,
  SET_REPRICE_FLAG,
  SET_BRANDCODE_IN_DROPDOWN,
  SET_REPRICE_LOADER,
  SET_OUTBOUND_OPTION,
  SET_INBOUND_OPTION,
  SET_PREVIOUS_VC,
  SET_ISNONGIGI_PNR,
  SET_IS_UNACCOMPANIED_MINOR,
  SET_RESET_CALC_DATA,
  SET_IS_ITINERARY_CHANGED,
  SET_AUTO_EMAIL_TYPE,
  SET_IS_RETRY_REQUEST_ERROR,
  SET_UPDATE_BOOKING_STATUS_POP_UP,
  SET_PROVIDER_NAME,
  SET_SHOW_CALCULATOR,
  SET_SHOW_CALCULATOR_MSG
} from "../storeConstants";

const initialState = {
  bookingSegment: [],
  bookingItenaries: {},
  pnr: "",
  bookingDetails: {},
  paymentDetail: [],
  bookingPaymentStatus: "",
  itinenaryFaresType: "",
  bookingDate: "",
  bookingStatus: "",
  priceStatus: false,
  pnrCancellationStatus: "",
  cancellationPopUp: false,
  passengerDetailsArray: "",
  bookWithPaymentFlag: false,
  fraudUserFlag: false,
  brandedItenaries: [],
  seatmapHeader: {},
  pnrPriceData: {},
  brandedStatus: "",
  searchStatus: "",
  paymentRules:{},
  paymentRulesForCheque:{},
  markupStatus:false,
  fraudPassengerList: [],
  fraudUserExists: false,
  fraudUserExistsWithPayment: false,
  pnrInfantCount: 0,
  pnrAdultCount: 0,
  pnrChildCount: 0,
  pnrFlag: false,
  createBookingData:{},
  priceFareData:"",
  ssrLoaderFlag:false,
  ssrSaveEditFlag:true,
  applyBtnClickFlag:false,
  landCruiseBookingNumber:"",
  agentEmergencyNumber:"",
  validationOnLandCruise:true,
  equivalentFareValue:{},
  equivalentFareLoader:false,
  repriceFare:"",
  repriceFareLoader:false,
  frequentFlyerDropdown:[],
  getPnrResult:"",
  bookingStatusCode:"",
  farePaymentAttempt: false,
  seatPaymentAttempt: false,
  farePaymentStatus : false,
  seatPaymentStatus : false,
  fopSelection:[],
  emailLanguage:"English",
  markupLoader:false,
  flowFrom:"",
  calcErrors:[],
  existingFareRemarkCheck:false,
  totalSellingPrice:'',
  tripTypeFlowFrom:"MyFiles",
  repricePnrRadioBtn:"LowestFare",
  selectedBrandedFare:{},
  marKupButtonCheck:false,
  marKupButtonCount:0,
  landCruiseBookingNumberCheck:false,
  disableSaveButton: false,
  allowSeatPayment: true,
  openQuoteDialog: false,
  autoReprice: false,
  paxTypeCodeErrorFlag:false,
  cancellationStatus:"",
  cancellationConfirmation:false,
  regularRefundCancellationDetails:{},
  offerIdTimeOut: false,
  expireTime:"",
  sendAutoEmail:false,
  savePassengerInfoForLaterPay: {},
  applyMarkupButton: true,
  cancellationBookingCode:"",
  saveButtonLoader: false,
  markupPopUp: false,
  initialItineraryFare:{},
  initialPaymentRules:{},
  initialPaymentRulesChq:{},
  unSavedChangesInMarkupFlag:false,
  unSavedChangesInMarkupPopup:false,
  updatePnrCalculatorFlow: false,
  enableBrandedFares:true,
  enablePerPassenger:false,
  outBoundBrandCodes:"",
  inBoundBrandCodes:"",
  outBoundBrandCodePerPassengers:[],
  inBoundBrandCodePerPassengers:[],
  validatingCarrier:"",
  brandCodeOutDropdown:[],
  saveCalculatorFlow: false,
  repriceFlag: false,
  brandCodeInDropdown:[],
  repriceLoader: false,
  outboundOption:[],
  inboundOption:[],
  previousVC:"",
  isNonGigiPNR: false,
  isUnaccompaniedMinor : false,
  resetCalcData: false,
  isItineraryChanged: false,
  autoEmailType: "Booking",
  isRetryRequestError: false,
  updateBookingStatusPopUp: false,
  providerName: "Sabre",
  showCalculator: true,
  showCalculatorMsg: ''
};

const FlightBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEGMENT:
      return {
        ...state,
        bookingSegment: action.payload,
      };

    case SET_REGULAR_REFUND_CANCELLATION_DETAILS:
      return {
        ...state,
        regularRefundCancellationDetails: action.payload,
      };
    

    case SET_ITINARY_FARE:
      return {
        ...state,
        bookingItenaries: action.payload,
      };

    case SET_PNR:
      return {
        ...state,
        pnr: action.payload,
      };

    case SET_PASSENGER_DATA:
      let newState = {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          passengers: action.payload,
        },
      };
      return newState

    case SET_BOOKING_DETAIL:
      return {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          merchantFeeAppliedOnTransaction: action.payload?.merchantFeeAppliedOnTransaction,
          passengers: action.payload.passengers,
          segments: action.payload.segments,
          itineraryFare: action.payload.itineraryFare,
          payments: action.payload.payments,
          agencyMarkups: action.payload.agencyMarkups,
          ticketingDetails: action.payload.ticketDetails
        },
      };
      case SET_TOTAL_SELLING_PRICE:
        return {
          ...state,
          totalSellingPrice: action.payload,
        };
    case SET_BOOKING_PAYMENT_STATUS:
      return {
        ...state,
        bookingPaymentStatus: action.payload,
      };
    case SET_ITINARY_FARES_TYPE:
      return {
        ...state,
        itinenaryFaresType: action.payload,
      };

    case SET_BOOKING_DATE:
      return {
        ...state,
        bookingDate: action.payload,
      };
    case SET_BOOKING_STATUS:
      return {
        ...state,
        bookingStatus: action.payload
      }
    case PRICE_STATUS:
      return {
        ...state,
        priceStatus: action.payload
      }
    case SET_PNR_CANCELLATION_STATUS:
      return {
        ...state,
        pnrCancellationStatus: action.payload
      }
    case SET_CANCELLATION_STATUS:
      return {
        ...state,
        cancellationStatus: action.payload
      }
      case SET_CANCELLATION_CONFIRMATION:
        return {
          ...state,
          cancellationConfirmation: action.payload
        }    
    case SET_CANCELLATION_POPUP:
      return {
        ...state,
        cancellationPopUp: action.payload
      }
    case SET_PASSENGER_DETAILS_ARRAY:
      return {
        ...state,
        passengerDetailsArray: action.payload
      }
    case SET_BOOK_WITH_PAYMENT_FLAG:
      return {
        ...state,
        bookWithPaymentFlag: action.payload
      }
    case SET_BRANDED_ITINARY_FARE:
      return {
        ...state,
        brandedItenaries: action.payload,
      };
    case SET_SEATMAP_HEADER:
      return {
        ...state,
        seatmapHeader: action.payload,
      };
    case SET_PNR_PRICE:
      return {
        ...state,
        pnrPriceData: action.payload,
      };
    case SET_BRANDED_STATUS:
      return {
        ...state,
        brandedStatus: action.payload,
      };
    case SET_SEARCH_STATUS:
      return {
        ...state,
        searchStatus: action.payload,
      };
    case SET_PAYMENT_RULES:
      return {
        ...state,
        paymentRules: action.payload,
      };
      case SET_PAYMENT_RULES_FOR_CHEQUE:
        return {
          ...state,
          paymentRulesForCheque: action.payload,
        };
      case SET_MARKUP_STATUS:
        return {
          ...state,
          markupStatus: action.payload,
        };
      case SET_PASSENGER_FRAUD_LIST:
        return {
          ...state,
          fraudPassengerList: action.payload
        };
      case SET_FRAUD_USER_EXISTS:
        return {
          ...state,
          fraudUserExists: action.payload
        };
      case SET_FRAUD_USER_EXISTS_WITH_PAYMENT:
        return {
          ...state,
          fraudUserExistsWithPayment: action.payload
        };
      case SET_FRAUD_USER_FLAG:
        return {
          ...state,
          fraudUserFlag: action.payload
        }
        case SET_ADULT_COUNT:
          return {
            ...state,
            pnrAdultCount: action.payload
          }
    
        case SET_CHILDREN_COUNT:
          return {
            ...state,
            pnrChildCount: action.payload
          }
    
    
        case SET_INFANT_COUNT:
          return {
            ...state,
            pnrInfantCount: action.payload
          }
    
    
        case SET_MYFILE_FLAG:
          return {
            ...state,
            pnrFlag: action.payload
          }

          case SET_CREATE_BOOKING_DATA:
            return {
              ...state,
              createBookingData: action.payload
            } 

            case SET_PRICING_FARE_DATA:
              return {
                ...state,
                priceFareData: action.payload
              }
              case SET_SSR_LOADER_FLAG:
                return {
                  ...state,
                  ssrLoaderFlag: action.payload
                }
                case SET_SSR_SAVE_EDIT_FLAG:
                  return {
                    ...state,
                    ssrSaveEditFlag: action.payload
                  } 
                  case SET_APPLY_BUTTON_CLICK_FLAG:
                    return {
                      ...state,
                      applyBtnClickFlag: action.payload
                    }
                case SET_LAND_CRUISE_BOOKING_NUMBER:
                  return {
                    ...state,
                    landCruiseBookingNumber: action.payload
                  }  
                  case SET_AGENT_EMERGENCY_NUMBER:
                    return {
                      ...state,
                      agentEmergencyNumber: action.payload
                    } 
                    case SET_VALIDATION_ON_LAND_CRUISE:
                    return {
                      ...state,
                      validationOnLandCruise: action.payload
                    } 
                    case SET_EQUIVALENT_FARE_VALUE:
                      return {
                        ...state,
                        equivalentFareValue: action.payload,
                      };
                      case SET_EQUIVALENT_FARE_LOADER:
                      return {
                        ...state,
                        equivalentFareLoader: action.payload,
                      };
                      case SET_REPRICE_FARE:
                        return {
                          ...state,
                          repriceFare: action.payload,
                        };
                        case SET_REPRICE_FARE_LOADER:
                          return {
                            ...state,
                            repriceFareLoader: action.payload,
                          };
                      case SET_FREQUENT_FLYER_DROPDOWN:
                        return {
                          ...state,
                          frequentFlyerDropdown: action.payload
                        }
                        case SET_GET_PNR_RESULT:
                          return {
                            ...state,
                            getPnrResult: action.payload
                          }
                          case SET_BOOKING_STATUS_CODE:
                            return {
                              ...state,
                              bookingStatusCode: action.payload
                            }
                  case SET_FARE_PAYMENT_ATTEMPT:
                    return {
                      ...state,
                      farePaymentAttempt: action.payload
                    }
                    case SET_SEAT_PAYMENT_ATTEMPT:
                      return {
                        ...state,
                        seatPaymentAttempt: action.payload
                      }
                    case SET_FARE_PAYMENT_STATUS:
                      return {
                        ...state,
                        farePaymentStatus: action.payload
                      }
                    case SET_SEAT_PAYMENT_STATUS:
                      return {
                        ...state,
                        seatPaymentStatus: action.payload
                      }
                      case SET_FOP_SELECTION:
                      return {
                        ...state,
                        fopSelection: action.payload
                      }
                      case SET_EMAIL_LANGUAGE:
                        return {
                          ...state,
                          emailLanguage: action.payload
                        }
                        case SET_MARKUP_LOADER:
                          return {
                            ...state,
                            markupLoader: action.payload
                          }
                          case SET_FLOW_FROM:
                            return {
                              ...state,
                              flowFrom: action.payload
                            }   
                          case SET_CALC_ERRORS:
                          return {
                            ...state,
                            calcErrors : action.payload
                          } 
                          case SET_EXISTING_FARE_REMARK_CHECK:
                            return {
                              ...state,
                              existingFareRemarkCheck : action.payload
                            }   
                          case SET_TRIPTYPE_FLOW_FROM:
                            return {
                              ...state,
                              tripTypeFlowFrom : action.payload
                            } 
                            case SET_REPRICE_PNR_RADIO_BUTTON:
                              return {
                                ...state,
                                repricePnrRadioBtn : action.payload
                              }      
                              case SET_SELECTED_BRANDED_FARE:
                              return {
                                ...state,
                                selectedBrandedFare : action.payload
                              }
                              case SET_MARKUP_BUTTON_CHECK:
                                return {
                                  ...state,
                                  marKupButtonCheck : action.payload
                                } 
                                case SET_MARKUP_BUTTON_COUNT:
                                  return {
                                    ...state,
                                    marKupButtonCount : action.payload
                                  }
                                  case SET_LAND_CRUISE_BOOKING_NUMBER_CHECK:
                                    return {
                                      ...state,
                                      landCruiseBookingNumberCheck: action.payload
                                    } 
                                    case SET_DISABLE_SAVE_BUTTON :
                                      return{
                                        ...state,
                                        disableSaveButton: action.payload
                                      }   
                                      case ALLOW_SEAT_PAYMENT : 
                                      return{
                                        ...state,
                                        allowSeatPayment: action.payload
                                      }  
                                      case OPEN_QUOTE_DIALOG : 
                                        return{
                                          ...state,
                                          openQuoteDialog: action.payload
                                        }
                                        case SET_AUTO_REPRICE : 
                                        return{
                                          ...state,
                                          autoReprice: action.payload
                                        }
                                        case SET_PAX_TYPE_CODE_ERROR_FLAG : 
                                        return{
                                          ...state,
                                          paxTypeCodeErrorFlag: action.payload
                                        }
                                        
                                        case SET_OFFERID_TIMEOUT : 
                                        return {
                                          ...state,
                                          offerIdTimeOut: action.payload
                                        }
                                        case SET_EXPIRE_TIME:
                                          return{
                                            ...state,
                                            expireTime: action.payload
                                          }
                                          case SET_SEND_AUTO_EMAIL:
                                            return{
                                              ...state,
                                              sendAutoEmail: action.payload
                                            }
                                          case SET_PASSENGER_INFO_FOR_LATER_PAY:
                                            return {
                                              ...state,
                                              savePassengerInfoForLaterPay: action.payload
                                            }
                                            case SET_APPLY_MARKUP_BUTTON:
                                            return {
                                              ...state,
                                              applyMarkupButton: action.payload
                                            }
                                            case SET_CANCELLATION_BOOKING_CODE:
                                              return {
                                                ...state,
                                                cancellationBookingCode: action.payload
                                              }
                                              case SET_SAVE_BUTTON_LOADER:
                                              return {
                                                ...state,
                                                saveButtonLoader: action.payload
                                              }
                                              case SET_APPLY_MARKUP_POPUP:
                                              return {
                                                ...state,
                                                markupPopUp: action.payload
                                              }
                                              case SET_INITIAL_ITINERARY_FARE:
                                              return {
                                                ...state,
                                                initialItineraryFare: action.payload
                                              }
                                              case SET_INITIAL_PAYMENT_RULES:
                                              return {
                                                ...state,
                                                initialPaymentRules: action.payload
                                              }
                                              case SET_INITIAL_PAYMENT_RULES_CHQ:
                                              return {
                                                ...state,
                                                initialPaymentRulesChq: action.payload
                                              }
                                              case SET_UNSAVED_CHANGES_FLAG:
                                              return {
                                                ...state,
                                                unSavedChangesInMarkupFlag: action.payload
                                              }
                                        case SET_UPDATE_PNR_CALCULATOR_FLOW : 
                                        return{
                                          ...state,
                                          updatePnrCalculatorFlow: action.payload
                                        }
                                              case SET_ENABLE_BRANDED_FARES:
                                              return {
                                                ...state,
                                                enableBrandedFares: action.payload
                                              }
                                              case SET_ENABLE_PER_PASSENGER:
                                              return {
                                                ...state,
                                                enablePerPassenger: action.payload
                                              }
                                              case SET_OUTBOUND_BRAND_CODES:
                                                return{
                                                  ...state,
                                                  outBoundBrandCodes: action.payload
                                                }
                                                case SET_INBOUND_BRAND_CODES:
                                                return{
                                                  ...state,
                                                  inBoundBrandCodes: action.payload
                                                }
                                                case SET_OUTBOUND_BRAND_CODE_PER_PASSENGERS:
                                                return{
                                                  ...state,
                                                  outBoundBrandCodePerPassengers: action.payload
                                                }
                                                case SET_INBOUND_BRAND_CODE_PER_PASSENGERS:
                                                return{
                                                  ...state,
                                                  inBoundBrandCodePerPassengers: action.payload
                                                }
                                                case SET_VALIDATING_CARRIER:
                                                return{
                                                  ...state,
                                                  validatingCarrier: action.payload
                                                }
                                                case SET_BRANDCODE_OUT_DROPDOWN:
                                                return{
                                                  ...state,
                                                  brandCodeOutDropdown: action.payload
                                                } 
                                                case SET_SAVE_CALCULATOR_FLOW:
                                                return{
                                                  ...state,
                                                  saveCalculatorFlow: action.payload
                                                } 
                                                case SET_REPRICE_FLAG:
                                                return{
                                                  ...state,
                                                  repriceFlag: action.payload
                                                } 
                                                case SET_BRANDCODE_IN_DROPDOWN:
                                                return{
                                                  ...state,
                                                  brandCodeInDropdown: action.payload
                                                }
                                                case SET_REPRICE_LOADER:
                                                  return{
                                                    ...state,
                                                    repriceLoader: action.payload
                                                  }
                                                  case SET_OUTBOUND_OPTION:
                                                  return{
                                                    ...state,
                                                    outboundOption: action.payload
                                                  }
                                                  case SET_INBOUND_OPTION:
                                                  return{
                                                    ...state,
                                                    inboundOption: action.payload
                                                  }
                                                  case SET_PREVIOUS_VC:
                                                    return{
                                                      ...state,
                                                      previousVC: action.payload
                                                    }
                                                    case SET_ISNONGIGI_PNR:
                                                      return{
                                                        ...state,
                                                        isNonGigiPNR: action.payload
                                                      }
                                                      case SET_IS_UNACCOMPANIED_MINOR:
                                                      return{
                                                        ...state,
                                                        isUnaccompaniedMinor: action.payload
                                                      }
                                                      case SET_RESET_CALC_DATA:
                                                        return{
                                                          ...state,
                                                          resetCalcData: action.payload
                                                        }
                                                      case SET_IS_ITINERARY_CHANGED:
                                                        return{
                                                          ...state,
                                                          isItineraryChanged: action.payload
                                                        }
                                                        case SET_AUTO_EMAIL_TYPE:
                                                          return{
                                                            ...state,
                                                            autoEmailType:action.payload
                                                          }
                                                            case SET_IS_RETRY_REQUEST_ERROR:
                                                              return{
                                                                ...state,
                                                                isRetryRequestError: action.payload
                                                              }
                                                              case SET_UPDATE_BOOKING_STATUS_POP_UP:
                                                                return{
                                                                  ...state,
                                                                  updateBookingStatusPopUp: action.payload
                                                                }
                                                              case SET_PROVIDER_NAME:
                                                                return {
                                                                  ...state,
                                                                  providerName: action.payload
                                                                }
                                                                case SET_SHOW_CALCULATOR:
                                                                  return {
                                                                    ...state,
                                                                    showCalculator: action.payload
                                                                  }
                                                                  case SET_SHOW_CALCULATOR_MSG:
                                                                  return {
                                                                    ...state,
                                                                    showCalculatorMsg: action.payload
                                                                  }
                default:
                  return state;
              }
};

export default FlightBookingReducer;
