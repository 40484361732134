export const englishMessages = {
  // Labels //
  Gender: "Gender",
  Title: "Title",
  Date_of_birth: "Date of birth",
  Type: "Type",
  Select_Meal: "Select Meal",
  Select_Assistance: "Select Assistance",
  "Select Assistance": "Select Assistance",
  Select_Wheelchair: "Select Wheelchair",
  "Select Wheelchair": "Select Wheelchair",
  Frequent_Flyer_Program: "Frequent Flyer Program",
  Passport_Expiration: "Passport Expiration",
  Issue_Country: "Issue Country",
  Travelbrands_Land_Component_Booking_Number:
    "Travelbrands Land Component Booking Number",
  Travelbrands_Cruise_Component_Booking_Number:
    "Travelbrands Cruise Component Booking Number",
  "Travelbrands Land/Cruise Component Booking Number": "Travelbrands Land/Cruise Component Booking Number",
  Morning: "Morning",
  Afternoon: "Afternoon",
  Night: "Night",
  Evening: "Evening",
  Enter_Record_PNR: "Enter Record PNR",
  Source: "Source",
  Add_land_portion_fares: "Add land portion fares",
  Add_cruise_fare: "Add cruise fare",
  Sabre: "Sabre",
  Amadeus: "Amadeus",
  PNR_No: "PNR",
  Pax_Names: "Pax Names",
  Pax_Name: "Pax Name",
  Agent_Name: "Agent Name",
  Invoice_Number: "Invoice Number",
  By_Reservation_Dates: "By Reservation Dates",
  "Reservation Dates":"Reservation Dates",
  By_Departure_Dates: "By Departure Dates",
  "Departure Dates":"Departure Dates",
  Client_Name: "Client Name",
  Client_Email: "Client Email",
  City_Pairs: "City Pairs",
  Airline: "Airline",
  Show_Emailed_Quote: "Show Emailed Quote",
  Form_of_payment: "Form of payment",
  I_Understand_that_prepaid_seats_may_not_be_Refundable:
    "I Understand that prepaid seats may not be Refundable.",
  Please_enter_Client_Name: "Please enter Client Name",
  Flying_From: "Flying From",
  Flying_To: "Flying To",
  Departure_date_time: "Departure date & time",
  Return_Date: "Return Date",
  Passengers: "Passengers",
  Return_date_amp_time: "Return date & time",
  Adults: "Adults",
  Children: "Children",
  Infants: "Infants",
  Type_of_Fares: "Type of Fares",
  Air: "Air",
  Land: "Land",
  Cruise: "Cruise",
  "(Air)": "(Air)",
  "(Land)": "(Land)",
  "(Cruise)": "(Cruise)",
  Type_of_Passengers: "Type of Passengers",
  ADT: "ADT",
  ADT_PV: "ADT PV",
  JCB: "JCB",
  PFA: "PFA",
  ITX: "ITX",
  // "Basic economy":"Basic economy" , commeted
  Basic_Economy_Fares: "Basic Economy Fares",
  Maximum_connections: "Maximum connections",
  Changes_allowed: "Changes allowed",
  Both: "Both",
  With_Fee: "With Fee",
  Without_Fees: "Without Fees",
  Alliance: "Alliance",
  Sky_Team: "Sky Team",
  Star_Alliance: "Star Alliance",
  Oneworld: "Oneworld",
  Search_nearby_airports: "Search nearby airports",
  At_least_1_free_baggage: "At least 1 free baggage",
  Select_Travel_Agency: "Select Travel Agency",
  Select_Travel_Agent: "Select Travel Agent",
  User_Name: "User Name",
  Password: "Password",
  Include: "Include",
  Carriers: "Carriers",
  Fares_with_free_bag: "Fares with free bag(s)",
  Fares_with_free_seat_selection: "Fares with free seat selection",
  Fare_matrix_showing: "Fare matrix showing lowest fare for Departure on  ",
  and_Return_on: "  and Return on",
  and_on: "  and on  ",
  "I confirm that the names":
    "I confirm that the names and details displayed above are complete and correct to the best of my knowledge. By booking a trip through this software, I confirm that I have read and agree to the terms and conditions outlined therein.",
  Signature: "Signature",
  "Voided":"Voided",
  "I want to continue and book":
    "I want to continue and book a seat for the infant.",
  "I agree to contact the airline.":
    "I agree to contact the airline. Continue and book.",
  "Apply for all flights": "Apply for all flights",
  "Spacial Type": "Spacial Type",
  "Spacial Wheelchair": "Spacial Wheelchair",
  "Spacial Assitance": "Spacial Assitance",
  "Frequent Flyer Programme": "Frequent Flyer Programme",
  "Frequent Traveler Number": "Frequent Traveler Number",
  "Redress Number": "Redress Number",
  "Additional Markup": "Additional Markup",
  "Known Traveler Number": "Known Traveler Number",
  "Departure City": "Departure City",
  "Arrival City": "Arrival City",
  position: "position",
  "Credit Card": "Credit Card",
  Cheque: "Cheque",
  "Card Number": "Card Number",
  "Cardholder Name": "Cardholder Name",
  "Expiry Date": "Expiry Date",
  CVV: "CVV",
  "Cheque Number": "Cheque Number",
  "Apply same payment method for all the travelers":
    "Apply same payment method for all the travelers",
  "Net Fares": "Net Fares",
  "Land Net Fares": "Land Net Fares",
  "Cruise Net Fares": "Cruise Net Fares",
  "Net Fare": "Net Fare",
  "Pax Type": "Pax Type",
  Commissionable: "Commissionable",
  "Non Commissionable": "Non Commissionable",
  "Net Base Fare": "Net Base Fare",
  "Calculator searches for all fare contract types AIR-LAND-CRUISE":
    "Calculator searches for all fare contract types AIR-LAND-CRUISE",
  "Reprice the itinerary at the lowest available fares":
    "Reprice the itinerary at the lowest available fares",
  "Reprice the itinerary in current classes of service using the same fare option when known":
    "Reprice the itinerary in current classes of service using the same fare option when known",
   "Mixed Cabin":'Mixed Cabin',
  // links //
  TravelGenie: "TravelGenie",
  Sunquest: "Sunquest",
  Flights: "Flights",
  Hotels: "Hotels",
  Cars: "Cars",
  Cruises: "Cruises",
  Add_Ons: "Add-Ons",
  Disney: "Disney",
  Featured_Destinations: "Featured Destinations",
  Specialty_Travel_Lujure: "Specialty Travel/Lujure",
  SAVED_ITINENARY: "SAVED ITINENARY",
  MY_BOOKINGS: "MY BOOKINGS",
  GROUPS: "GROUPS",
  search_Results: "Search Results",
  My_files: "My Files",
  My_Saved_Quotes: "My Saved Quotes",
  Calculator: "Calculator",
  Default_View: "By Airline",
  By_Fare_type: "By Fare type",
  "Back to results": "Back to results",
  "Back to calculator": "Back to calculator",
  "Edit Details": "Edit Details",
  "Select Seats": "Select Seats",
  "Frequent Traveler Numbers, Passport Info":
    "Frequent Traveler Numbers, Passport Info",
  "Frequent Traveler, Nexus":
    "Frequent Traveler, Nexus, Redress, Passport Info",
  Save: "Save",
  Edit: "Edit",
  Edits:"Edit",
  "Want to avoid fees ?": "Want to avoid fees ?",
  "There will be two separate":
    "There will be two separate charges on the passengers credit card statement",
  "Frequent Traveler, Redress, Passport Info":
    "Frequent Traveler, Redress, Passport Info",
    "Frequent Traveler, Passport Info":
    "Passport Info",
    "Frequent Traveler":"Frequent Traveler",

  // Buttons //
  Search: "Search",
  Search_3_days: "Search +/- 3 days",
  Clear_Form: "Clear Form",
  RoundTrip: "Round Trip",
  OneWay: "One Way",
  OpenJaw: "Open Jaw",
  MultiCity: "Multi City",
  Modify_Search: "Modify Search",
  Apply_Filter: "Apply Filter",
  Yes: "Yes",
  No: "No",
  "Apply Payment": "Apply Payment",
  Reset: "Reset",
  Send: "Send",
  Cancel: "Cancel",
  "Confirm Seats": "Confirm Seats",
  "Reset All": "Reset All",
  "Complete Selection": "Complete Selection",
  "Next Flight": "Next Flight",
  "Continue Adding Seats": "Continue Adding Seats",
  "Remove Selected Seats": "Remove Selected Seats",
  "Apply Markup": "Apply Markup",
  View: "View",
  "Display List": "Display List",
  "Free Baggage": "Free Baggage",
  "Send Quote": "Save Quote",
  Continue: "Continue",
  book: "Book",
  "Seat Map": "Seat Map",
  "View Rules": "View Rules",
  "Hold Without Payment": "Hold Without Payment",
  "Book With Payment": "Book With Payment",
  "Cancel Booking": "Cancel Booking",
  "Default Markup":"Default Markup",
  OK: "OK",
  Close: "Close",
  "Clear All Filters": "Clear All Filters",
  Filter: "Filter",
  "Log out": "Log out",
  "Waiting For Update":"Waiting For Update...",
  // DropDown Lists //

  Time: "Time",
  Cabin: "Cabin",
  Economy: "Economy",
  PremiumEconomy: "Premium Economy",
  "Premium Economy": "Premium Economy",
  Business: "Business",
  Mobile: "Mobile",
  Home: "Home",
  "Meet and Assist ": "Meet and Assist ",
  Blind: "Blind",
  Deaf: "Deaf",
  "Wheelchair/Passenger can walk up stairs":
    "Wheelchair/Passenger can walk up stairs",
  "Wheelchair/Passenger can walk to seat":
    "Wheelchair/Passenger can walk to seat",
  "Wheelchair/Passenger must be carried":
    "Wheelchair/Passenger must be carried",
  "Wheelchair on board": "Wheelchair on board",
  First: "First",
  "Select Carriers": "Select Carriers",
  "Sky Team": "Sky Team",
  "Star Alliance": "Star Alliance",
  "One World": "One World",
  "All Carriers": "All Carriers",
  "Specific Airlines": "Specific Airlines",
  "Direct flights": "Direct flights",
  One: "One",
  Two: "Two",
  Three: "Three",
  Connections: "Connections",
  "connection airports": "Connection Airports",
  Class: "Class",

  // Placeholders, Div, Spans, Button & filters //
  Select_Agent: "Select Agent",
  Select_Agency: "Select Agency",
  Enter_any_airline: "Enter any airline",
  Inner_Content: "Inner Content",
  Connection_duration: "Connection Duration",
  Trip_Duration: "Trip Duration",
  DEPARTURE_TIMES: "Departure Times",
  ARRIVAL_TIMES: "Arrival Times",
  AIRLINES: "Airlines",
  Alliances: "Alliances",
  Fare_Type: "Fare Type",
  Price_Range: "Price Range",
  Price_allowing_refunds: "Price allowing refunds",
  All_Prices: "All Prices",
  Fares_with_free_bag_allowance: "Fares with free bag allowance",
  "Please enter Required Fields.": "Please enter Required Fields",
  "Please enter First Name.": "Please enter first name.",
  "Please enter Last Name.": "Please enter last name.",
  "Please enter Email.": "Please enter email.",
  "Please enter Phone Number.": "Please enter valid phone number.",
  "Please enter 2 or more characters": "Please enter 2 or more characters",
  "Please enter 1 or more characters": "Please enter 1 or more characters",
  "Choose Fare Options": "Choose Fare Options",
  "Passengers Details": "Passengers Details",
  "Includes merchant fees": "Includes merchant fees",
  "Enter an amount lower than": "Enter an amount lower than",
  "for Adult": "for Adult",
  "for Child": "for Child",
  "for Infant": "for Infant",
  "Pay using Cheque": "Pay using Cheque",
  "If Paying By Cheque": "If Paying By Check",
  "If Paying By Card": "If Paying By Card",
  "Back to Confirmation":"Back to Confirmation",
  "Go to Void cancellation Verbiage":"Thank you. The itinerary for file XXXXXX has been canceled and all electronic documents voided.",
  "Go to Void cancellation Verbiage1":"If paid by credit card, it may take a few days to see the credit card authorization released.",
  "Regular Refund Verbiage" : "Thank you. The itinerary for file XXXXXX  has been canceled and your file will be reviewed by our refund department.",
  "Regular Refund Verbiage1" : "Any documents issued and eligible for a refund will be processed within seven days.",
  "Regular Refund Verbiage2" : "If paid by credit card, the refund may not be processed until the next credit card statement.",
  "On Option Cancellation Verbiage":"Thank you. The itinerary on file XXXXXX has been cancelled.",
  "Go to 24hrs Refund Verbiage":"Thank you. The itinerary for file XXXXXX  has been cancelled and all electronic tickets will be  refunded in full.",
  "Go to 24hrs Refund Verbiage1":"If paid by credit card, the refund may not be processed until the next credit card statement.",
  "Go to 24hrs Refund Verbiage2":"If Electronic Miscellanous Documents , such as  prepaid seats emds,  are present in your file,the refund is on request and subject to the carrier's approval.",
  "Paid Not Ticketed Cancellation Verbiage":"Thank you. The itinerary on file XXXXXX has been cancelled.",
  "Paid Not Ticketed Cancellation Verbiage1":"If paid by credit card, it might take a few days to see the credit card authorisation released.",
  AirLine: "Airline",
  Intair: "Intair",
  "Amount Breakup:": "Amount Breakup:",
  To: "To",
  Departure: "Departure",
  Return: "Return",
  "View 2 More City Pairs": "View 2 More City Pairs",
  Adult: "Adult",
  Child: "Child",
  Infant: "Infant",
  From: "From",
  "Are you sure?": "Are you sure?",
  "Do you want to Cancel ?": "Do you want to Cancel ?",
  "Your file shows that electronic documents":"Your file shows that electronic documents have been issued.",
  "The itinerary will be cancelled":"The itinerary will be cancelled and  the file will be sent to our refund department.",
  "Any documents issued and eligible":"Any documents issued and eligible for a refund will be processed within 7 days.",
  "If paid by credit card, the refunded amount":"If paid by credit card, the refunded amount may not show until the next credit cart statement.",
  "Are you sure you want to cancel" : "Are you sure you want to cancel ?",
  "Your file is currently":
    "Your file is currently on option are you sure you want to cancel ?",
  "Your file shows that a payment":
    "Your file shows that a payment has been applied but documents have not been issued yet are you sure you want to cancel ?",
  "TOTAL FOR PREPAID SEATS": "TOTAL FOR PREPAID SEATS",
  TOTAL: "TOTAL",
  "Total Markup": "Total Markup",
  "Equivalent Published Fare": "Equivalent Published Fare",
  "There is no equivalent published":
    "There is no equivalent published fare for classes of service booked.",
  "We are sorry":
    "We are sorry, there is no equivalent published fare for this itinerary.",
  "Airline taxes & fees": "Airline taxes & fees",
  Per: "PER",
  "Base Fare": "Base Fare",
  "Per Infant": "PER INFANT",
  "Per child": "PER CHILD",
  "Per Adult": "PER ADULT",
  "All Airlines": "All Airlines",
  "Total Trip Duration · Under 20hr": "Total Trip Duration · Under 20hr",
  "Layover Duration · Any": "Layover Duration · Any",
  "Hide Nearby Airports": "Hide Nearby Airports",
  "23 fares": "23 fares",
  "28 fares": "28 fares",
  "Hide Changes of Airport": "Hide Changes of Airport",
  "fares from": "fares from",
  "Direct only": "Direct only",
  Upto: "Upto",
  "Sort & FILTER": "Sort & FILTER",
  "All Alliances": "All Alliances",
  "Low Cost Carriers": "Low Cost Carriers",
  "Multi-ticket pricing": "Multi-ticket pricing",
  "Published fares": "Published fares",
  "Bulk fares": "Bulk fares",
  "Cruise fares": "Cruise fares",
  "Land Fares": "Land Fares",
  "Change Date":"Change Date",
  "No Data": "No Data",
  Duration: "Duration",
  "Change Fees": "Change Fees",
  "Current Commission": "Current Commission",
  "Send Email": "Send Email",
  Delete: "Delete",
  "Ticket By: INSTANT PURCHASE": "Ticket By: INSTANT PURCHASE",
  "Ticket By:": "Ticket By:",
  "View Details": "View Details",
  "No Result": "No Result",
  "Do you want to Delete?": "Do you want to Delete?",
  QuoteList: "QuoteList",
  Details: "Details",
  "Quote For:": "Quote For:",
  "Markup Applied": "Markup Applied",
  Taxes: "Taxes",
  Markup: "Markup",
  "Total payable Amount": "Total payable Amount",
  "Enter new Card Details": "Enter new Card Details",
  "Same card": "Same card",
  "Same Card ending in": "Same Card ending in",
  "Added in the fare:": "Added in the fare:",
  all: "ALL",
  ALL:"ALL",
  "For seats to be confirmed":
    "For seats to be confirmed, its mandatory to select seats for",
  "the travelers.": "the travelers.",
  "Your Service Fee Per Passenger": "Your Service Fee Per Passenger",
  "Select title.": "Select title.",
  "Please Select valid DOB.": "Please select valid DOB.",
  "No result found": "No result found.",
  "Select gender.": "Select gender.",
  "First Name": "First Name",
  "Middle Name": "Middle Name",
  "Last Name": "Last Name",
  "Phone Number": "Phone Number",
  "Email Address": "Email Address",
  "Online refund procedure and Form": "Online refund procedure and Form",
  "Record Locator": "Record Locator",
  "Total Duration:": "Total Duration :",
  Outbound: "Outbound",
  "Flight Details": "Flight Details",
  Inbound: "Inbound",
  Layover: "Layover",
  "Price paid for prepaid seats":
    "Price paid for prepaid seats have been updated by the carriers.",
  "Ticket No :": "Ticket No :",
  "EmdTicket No :": "EmdTicket No :",
  "Passport Number -": "Passport Number -",
  "Passport Expiration -": "Passport Expiration -",
  "Issued Country -": "Issued Country -",
  "Travel Agent Information": "Travel Agent Information",
  "TRAVELBRANDS - BUSINESS": "TRAVELBRANDS - BUSINESS",
  "Booking Status": "Booking Status",
  "Ticketing Status:": "Ticketing Status:",
  "Not ticketed": "Not ticketed",
  Failed: "Failed",
  TICKETED: "TICKETED",
  Ticketed: "Ticketed",
  CANCELLED: "CANCELLED",
  Cancelled: "Cancelled",
  "Please enter Valid email":"Please enter valid email.",
  "*Invalid Expiry Date": "*Invalid Expiry Date",
  "cached_expired":"It seems you kept the page open for quite some time. Performing a new search now to show you the latest results.",
  "Email Booking": "Email Booking & Electronic tickets",
  "ON HOLD": "ON HOLD",
  "On Hold": "On Hold",
  "Travelbrands Service": "Travelbrands Service Fees Per Passenger",
  "Taxes Included": "Taxes Included",
  "Fare Option": "Fare Option :",
  "All fees incurred by providing":
    "All fees incurred by providing a fictitious booking number will be entirely charged to the travel agency, including debit memos, claims or legal proceeding. Airline may ask for voucher before issuing a boarding pass.",
  "Cards Accepted": "Cards Accepted",
  "on Airline": "on Airline",
  "Credit card payment on Intair": "Credit card payment on Intair",
  "By Cheque": "By Cheque",
  "Total Amount Paid": "Total Amount Paid",
  "Booking fee": "Booking fee",
  "Ticketing fee": "Ticketing fee",
  "Total Taxes Fees:": "Total Taxes & Fees:",
  "Review your booking": "Review your booking",
  "Flight Selected": "Flight Selected",
  "Booking Details": "Booking Details",
  Payments: "Payments",
  "Booking is confirmed!": "Booking is confirmed!",
  "Do you want to save records?": "Do you want to save records?",
  "Mandatory land component":
    "Mandatory land component sold by TravelBrands is required. Travel agent agrees to penalties imposed if not followed.",
  "Mandatory cruise sold":
    "Mandatory cruise sold by TravelBrands is required. Travel agent agrees to penalties imposed if not followed.",
  "Selected Fare Option:": "Selected Fare Option:",
  "Brand Code :": "Brand Code :",
  "Basic Seat": "Basic Seat",
  "Standard Seat": "Standard Seat",
  "Change after departure": "Change after departure",
  "Change before departure": "Change before departure",
  "Refund after departure": "Refund after departure",
  "Refund before departure": "Refund before departure",
  Priority: "Priority",
  "Priority baggage": "Priority baggage",
  "Priority check in": "Priority check in",
  "Priority security": "Priority security",
  "Priority boarding": "Priority boarding",
  "View Seat map": "View Seat map",
  "Checked bags included": "Checked bags included",
  Bags: "Bags",
  "Cabin bag": "Cabin bag",
  Preferred: "Preferred",
  "Publish Non Commissionable Fare": "Publish Non Commissionable Fare",
  "Pricing per adult": "Pricing per adult",
  Fees: "Fees",
  "Changes and Refund": "Changes and Refund",
  "Changeable ticket": "Changeable ticket",
  "Refund after checkin closure": "Refund after checkin closure",
  "Refund before checkin closure": "Refund before checkin closure",
  "Layover in": " Layover in ",
  "Loyalty Points": "Loyalty Points",
  "Travelbrands has found a better fare from":
    "Travelbrands has found a better fare from",
  "Airline has increased the fare from": "Airline has increased the fare from",
  "Payment failed": "Payment failed. Please use a different card.",
  "Payment failed. Please":
    "Payment failed. Please use a different card or choose Cheque.",
  "Additional Bags": "Additional Bags",
  "up to": "Up To",
  "INSTANT PURCHASE": "INSTANT PURCHASE",
  "Total Flight Time": "Total Flight Time",
  "Total Trip Duration": "Total Trip Duration",
  "Seats": " STD SEATS",
  "Fare Basis": "Fare Basis",
  Facilities: "Facilities",
  "Traveller details": "Traveller details",
  "matches our fraudulent passenger names database.":
    "matches our fraudulent passenger names database. If you are confident that this passenger is not a fraudster, contact us to proceed with the booking.",
  "Land Confirmation No": "Land Confirmation No",
  "Special Request to all Flight" : "Special requests will apply to all flights. Want to add different special requests for each flight?",
  "Special Request to each Flight": "Want to add special requests that apply to all flights?",
  "Click here" : "Click here",
  "Passenger Details": "Passenger Details",
  "Payment Summary": "Payment Summary",
  "Price Summary": "Price Summary",
  "Add Traveler Info": "Add Traveler Info",
  "Add your markup amount": "Add your markup amount",
  "Taxes and Fees": "Taxes and Fees",
  "Operated by:": "Operated by : ",
  "Economy Class": "Economy Class",
  "Check-in": "Check-in",
  Piece: "  Piece",
  Penalties: "Penalties",
  Rules: "Rules",
  "Please check Date": "Please check Date",
  Seat: "Seat",
  "Change in case of no show": "Change in case of no show",
  "- Primary Contact": "- Primary Contact",
  "Primary Contact Info": "Primary Contact Info",
  "Contact Details": "Contact Details",
  "Your Contact information":
    "Your Contact information will be shared with the airline",
  "Special Request": "Special Request",
  "Seat Selection Details": "Seat Selection Details",
  "Seat Selection": "Seat Selection",
  "No seats are selected yet": "No seats are selected yet",
  "Waiting For Seat Update...": "Waiting For Seat Update...",
  "Prepaid Seats Were Not Booked":
    "Prepaid Seats Were Not Booked Because Tickets Haven’t Been Issued.",
  or: "or",
  Male: "Male",
  Female: "Female",
  "Any request to process changes":
    "Any request to process changes or corrections for passenger's last, second, first names and title are now subject to our service",
  "fees of 50.00 $/": `fees of 50.00 replacewithDollarValue/passenger plus applicable taxes.`,
  "You are liable for providing":
    " You are liable for providing the names and surnames as per written on the passport",
  Payment: "Payment",
  "By Card Ending In": "By Card Ending In",
  "Please Note that the taxes were increased":
    "Please Note that the taxes were increased",
  "Please Note that the taxes were decreased":
    "Please Note that the taxes were decreased",
  "Edit Markup": "Edit Markup",
  "Existing seat": "Existing seat",
  "Class Seat": "Class Seat",
  "New seat": "New seat",
  "Preferred Free": "Preferred Free",
  "Preferred Paid": "Preferred Paid",
  Free: "Free",
  Paid: "Paid",
  PAID: "PAID",
  "Not a Seat": "Not a Seat",
  "Not Available": "Not Available",
  Closet: "Closet",
  Galley: "Galley",
  Lavatory: "Lavatory",
  "Contact Carrier": "Contact Carrier",
  Exit: "Exit",
  "This seat map is for viewing":
    "This seat map is for viewing only, you'll select your seat at a later time.",
  "Prepaid seats must be booked":
    "Prepaid seats must be booked directly with the carrier.",
  Limitation: "Limitation",
  "Exit Door": "Exit Door",
  "Sharing the primary contact": "Sharing the primary contact information",
  "Select Seat": "Select Seat",
  "i.e. mobile phone number": "i.e. mobile phone number and/or email address",
  "with the airlines is mandatory":
    "with the airlines is mandatory. Airlines will use this information for operational notices such as flight cancellation or schedule changes. Airlines do not allow to provide the travel agent' contact information instead of that of the passenger.",
  "The maximum number of passengers":
    "The maximum number of passengers you can book is 9. Please try again.",
  "Tkt By": "Tkt By",
  "Sort By": "Sort By",
  "Search By": "Search By",
  "Reprice PNR": "Reprice PNR",
  "Show list for": "Show list for",
  customDate: "Custom Date",
  "Custom Date": "Custom Date",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  Today: "Today",
  Yesterday: "Yesterday",
  "This Week": "This Week",
  "This Month": "This Month",
  LASTWEEK: "Last Week",
  ARCHIEVED: "Archived",
  Remove: "Remove",
  "Add Flight": "Add Flight",
  "Close Advanced Search": "Close Advanced Search",
  "Prepaid Seats Payment": "Prepaid Seats Payment",
  "Travel within 72 hours":
    "Travel within 72 hours may be Fraud. Do you want to proceed with the booking?",
  "Travel within 72 hours isn’t": "Travel within 72 hours isn’t allowed.",
  "More City Pairs": " More City Pairs",
  "Your File Shows That Electronic Documents Are Issued":
    "Your File Shows That Electronic Documents Are Issued. The Itinerary Will Be Cancelled And All Electronic Documents Voided.",
  "Ticketing Status Verbiage" : "If paid by credit card, it may take a few days to see the credit card authorization released.",
  Reservation: "Reservation",
  Status: "Status",
  Destination: "Destination",
  "Multiple Fare Options":"Multiple Fare Options",
  "PNR Number": "PNR Number",
  "Important Note:": "Important Note:",
  "If Electronic Miscellaneous Documents":
    "If Electronic Miscellaneous Documents, Such As Prepaidseats Emds, Are Present In Your File, The Refund Is On Request And Subject To The Carrier's Approval.",
  "We Are Sorry But Online Cancellation For This File":
    "We are sorry but online cancellation for this file",
  "Is Not Allowed. Please Contact Us.": "is not allowed. Please contact us.",
  "Upon completing and submitting":
    "Upon completing and submitting this form, your file would be sent off to our refund's department for review. The refund conditions will be verified for each ticket/s",
  "electronic miscellaneous documents(EMDs). &":
    "electronic miscellaneous documents(EMDs).",
  "If refundable": "If refundable",
  "The carrier's cancellation fees":
    "The carrier's cancellation fees will be calculated and deducted in accordance with the applicable fare rules.* Note: The most restrictive cancellations fee may apply",
  "Fuel and miscellaneous surcharges":
    "Fuel and miscellaneous surcharges coded YQ/YR are subject to each carrier's cancellation policy and may or may not be refunded.",
  "Travelbrands service fees will be applicable":
    "Travelbrands service fees will be applicable only if the carrier's cancellation fees are present in the fare rules.",
  "Travelbrands administrative":
    "Travelbrands administrative fees for payment of airline tickets by credit card are not refundable.",
  "Loyalty points awarded":
    "Loyalty points awarded will be adjusted accordingly.",
  "Your commission in full": "Your commission in full will be claimed.",
  "You can charge your own agency's":
    "You can charge your own agency's service fees and include your commission recall in the total.",
    "Refund details and comment":
    "Refund details and comments will be indicated on the final invoice.",
  "Refund details and comments":
    "Refund details and comments will be indicated on the final invoice.",
  "If 100% non-refundable": "If 100% non-refundable",
  "Travelbrands service's fees do not apply.":
    "Travelbrands service's fees do not apply.",
  "If the value of the unused":
    "If the value of the unused ticket can be used toward the purchase of a new ticket, it will be indicated on your final invoice.",
  "Some airport taxes may be":
    "Some airport taxes may be refundable according to each carrier's refund policy. We will verify accordingly and in that case travelbrands service fees will apply.",
  "Prepaid Seats": "Prepaid Seats",
  "No Data To Show": "No Data To Show",
  "Calculator needs your 4 digits Intair agency code" : "Calculator needs your 4 digits Intair agency code to search for prices for your file.",
  "Add this remark in your pnr exactly as shown":"Add this remark in your pnr exactly as shown:",
  "Intair code" : "5**PNR OWNER-CXXXX** .",
  "XXXX is your Intair 4-digit agency code":"XXXX is your Intair 4-digit agency code.  Add 0 to your code if it contains less than 4 numerical characters. E.g. C0256 or C0066.",
  "Do not forget to save the remark":"Do not forget to save the remark  (ER) before submitting your file again.",
  Traveler: "Traveler",
  "Emergency Exit Row": "Emergency Exit Row",
  "Free seats": "Free seats",
  "Paid Seats": "Paid Seats",
  "Existing Fare": "Existing Fare",
  "Maximum Markup Allowed" : "Maximum Markup Allowed",
  "New option date exceeds maximum permitted date":"New option date exceeds maximum permitted date.",
  MR: "MR",
  MS: "MS",
  MRS: "MRS",
  DR: "DR",
  MSTR: "MSTR",
  MISS: "MISS",
  "Add Your Travelbrands cruise":
    "Add Your Travelbrands cruise component booking number",
  "Add Your Travelbrands land component":
    "Add Your Travelbrands land component booking number",
  "Add Your Travelbrands land/cruise component booking number":"Add Your Travelbrands land/cruise component booking number",
  "":"",
  For: "For",
  "Fare is": "Fare is",
  by: "by",
  "Cancel Details": "Cancel Details",
  "View Branded Fares": "View Branded Fares",
  Itinerary: "Itinerary",
  "Change Flight": "Change Flight",
  "There will be two seprate charges on the passangers credit card statement":
    "There will be two seprate charges on the passangers credit card statement",
  "Charged by": "Charged by",
  "Showing lowest fare for +/- 3 days": "Showing lowest fares excluding PUB(WEB) fares for +/- 3 days",
  "Failed to Update": "Failed to Update:",
  "New Fares": "New Fares",
  "Select type": "Select type.",
  Required: "Required",
  "Frequent Traveler Airline Code/Name": "Frequent Traveler Airline Code/Name",
  "Frequent Traveler Airline...": "Frequent Traveler Airline...",
  "Your File Shows That Electronic Documents Have Been Issued.":
    "Your File Shows That Electronic Documents Have Been Issued. The Itinerary Will Be Cancelled And All Electronic Tickets Refunded In Full. If Paid By Credit Card The Refund May Not Be Processed Until Next Credit Card Statement.",
  "We are sorry,a technical issue prevents":
    "We are sorry,a technical issue prevents us from processing your payment online.Please Contact Us.",

  "   Payment failed": "Payment failed.",
  "Enter Country": "Enter Country",
  "Special character not allowed": "Special character not allowed",
  "More Trips": " More Trips",
  Direct: "Direct",
  AnyTime: "AnyTime",
  "Cancellation Fees": "Cancellation Fees",
  "Provider Id": "Provider Id:",
  Baggage: "Baggage",
  Terminal: "Terminal ",
  "Fare results is a combination of multiple":
    "Fare results is a combination of multiple fare basis. Penalties are subject to multiple fees and conditions. Refer to RULES of each fare basis.",
  "over wing seats": "over wing seats",
  Amount: "Amount",
  "seat map is for viewing only.":
    "seat map is for viewing only. You'll select your seat at a later time.",
  "Lower Deck": "Lower Deck",
  "Prepaid seats can be selected":
    "Prepaid seats can be selected after making a booking.",
  "Advanced Search": "Advanced Search",
  fares: " fares",
  "Enter Airline": "Enter Airline",
  "Enter Name": "Enter Name",
  "Change & Cancellation": "Change & Cancellation",
  "Fee for additional bag": "Fee for additional bag ",
  "Enter valid First Name": "Enter valid First Name",
  "Enter valid Last Name": "Enter valid Last Name",
  "Enter Gender": "Enter Gender",
  "Enter Mobile Number": "Enter Mobile Number",
  "Enter Valid Email Address": "Enter Valid Email Address",
  specialChrError: "specialChrError",
  "Submit Payment": "Submit Payment",
  "Select Language": "Select Language",
  "Save Details": "Save Details",
  "Fare has Decreased": "Fare has Decreased",
  "Fare has Increased": "Fare has Increased",
  "Email Language": "Email Language",
  "Quote List": "Quote List",
  None: "None",
  Total: "Total",
  seats: "seats",
  "Add/Edit Markup": "Add/Edit Markup",
  "Want to add/edit special requests" : "Want to add/edit special requests, frequent traveler, redress or passport info?",
  "TOTAL SELLING AMOUNT": "TOTAL SELLING AMOUNT",
  "Payable Amount": "Payable Amount",
  "If Paying By": "If Paying By",
  "Amount Breakdown:": "Credit Card transactions:",
  "amount higher than the markup amount":"An amount higher than the markup amount indicated below will result in 2 separate credit card transactions.",
  "Adding a markup amount":"Adding a markup amount will result in 2 separate credit card transactions.",
  "Waiting For Markup Update...": "Waiting For Markup Update...",
  "Please use below option to edit Intair Fares" : "Modify Intair markup and fees",
  "Edit Intair Fares":"Edit Intair Fares",
  "Existing Fare without Additional Markup":
    "Existing Fare without Additional Markup",
  "PNR cannot be priced because it is Cancelled.":
    "PNR cannot be priced because it is Cancelled.",
  "No PQ present in the PNR": "No PQ present in the PNR",
  "Send email to": "Send email to",
  "Travel agent": "Travel agent",
  "Enter Record Locator": "Enter Record Locator",
  "Email to travel agent": "Email to travel agent",
  "Email to travel passanger": "Email to travel passanger",
  "Cancellation of a ticketed PNR":
    "Cancellation of a ticketed PNR is currently not allowed.Please contact us.",
  "Enter username or userId": "Enter username or userId",
  UNKNOWN: "UNKNOWN",
  Archived: "Archived",
  Cnx: " Cnx",
  "Enter Email": "Enter Email",
  "Please Enter Client Email": "Please Enter Client Email",
  "Enter Client Name": "Enter Client Name",
  "Enter Client Email": "Enter Client Email",
  English: "English",
  French: "French",
  "Payment_confirmation_msg":"I have read and understood the online refund procedure of TravelBrands",
  "No Private fare returned for": "No Private fare returned for",
  "Intair does not have the ticketing authority to issue tickets with this carrier.":"Intair does not have the ticketing authority to issue tickets with this carrier.",
  "No fare returned for": "No fare(s) returned for",
  "No Public fare returned for": "No Public fare returned for",
  "A technical issue occurred, please contact us":
    "A technical issue occurred, please contact us.",
  Reprice: "Reprice",
  "Passenger Name": "Passenger Name",
  "Passenger Type": "Passenger Type",
  "Our Commission": "Our Commission",
  "Status in PNR is Confirmed": "Status in PNR is Confirmed",
  "Status in PNR shows Unconfirmed.": "Status in PNR shows Unconfirmed.",
  "Status in file currently shows unconfirmed;":
    "Status in file currently shows unconfirmed; please check again later. After 24hrs, status shown is definitive.",
  "Status in PNR is failed": "Status in PNR is failed",
  Pending: "Pending",
  "MM/DD/YYYY": "MM/DD/YYYY",
  "MM/YYYY": "MM/YYYY",
  "mm/dd/yy":"mm/dd/yy",
  "Do you want to apply these markups.": "Do you want to apply these markups.",
  "Please Fill All The Mandatory Fields.": "Please Fill All The Mandatory Fields.",
  "Non Ticketed PNR Cancelled": "Non Ticketed PNR Cancelled",
  "EMD Ticket No": "EMD Ticket No :",
  "Confirmed - Payment Required": "Confirmed - Payment Required",
  "Payment Required" : "Payment Required",
  "Ticketed PNR Cancelled": "Ticketed PNR Cancelled",
  "Paid Non Ticketed PNR Cancelled": "Paid Non Ticketed PNR Cancelled",
  "Additional Filters": "Filtres supplémentaires",
  "Fare options cannot be displayed since no fare options are available for this fare.":	"Fare options cannot be displayed since no fare options are available for this fare.",
  "Have cancelled": "Have cancelled",
  "Unable to confirm": "Unable to confirm",
  "Flight Cancelled by Airline.": "Flight Cancelled by Airline.",
  "No action taken.": "No action taken.",
   Private:"Private",
   "*Required": "*Required",
   "Please specify full name":"Please specify full name",
   "Cheque Number should not be blank":	"Cheque Number should not be blank",
  "Unable to fetch branded fares, No branded fares available for this fare.":	"Unable to fetch branded fares, No branded fares available for this fare.",
  Published: "Published",
  Negotiated: "Negotiated",
  "Published Non-Commissionable":"Published Non-Commissionable",
  "Published Commissionable":"Published Commissionable",
  "Invalid Card Number":"Invalid Card Number",
   "EMD Payment In Progress":"EMD issuance for pre-paid seats is in progress",
   "EMD issuance not attempted": "EMD issuance wasn’t attempted because Ticket Issuance failed",
   "Fetch Exclusive Blockspace Flight Deals": "Please wait while we fetch exclusive flight deals.",
  // Paragraphs //
  "Full payment":
    "Full payment of air booking is required to secure prepaid seats",
  "As maximum": "As maximum",
  "characters are allowed in":
    "characters are allowed in first name, please change the first name to",
  "Maximum number of characters":
    "Maximum number of characters allowed for first and middle names have been reached; please remove middle names",
  "characters are allowed":
    "characters are allowed in middle names, please change the middle name to",
  "characters are allowed in middle names":
    "characters are allowed in middle names, please change by putting ",
    "with no space":"with no space.",
  "As maximum 25":
    "As maximum 25 characters are allowed in last name, please change the last name to",
  "A child must be 2 to 11 years old.":
    "A child must be 2 to 11 years old. Please enter valid DOB or relaunch your search considering this passenger as an adult.",
  "A child must be 2 to 11 years old. This passenger":
    "A child must be 2 to 11 years old. This passenger is considered as an infant that will turn 2 years old while en route. For that reason, carrier has special policy. Please contact us to make booking.",
  "A child must be 2 to  11 years old. Please relaunch":
    "A child must be 2 to  11 years old. Please relaunch your search considering this passenger as an infant.",
  "This passenger is considered as an infant":
    "This passenger is considered as an infant that will turn 2 years old while en route. For that reason, carrier has special policy. Please contact us to make booking.",
  "A infant must be less than 2 years old.":
    "A infant must be less than 2 years old. Please relaunch your search considering this passenger as an Child.",
  "You are about to book an unaccompanied minor (UMNR).":
    "You are about to book an unaccompanied minor (UMNR). You must contact the carrier to check if your passenger will be accepted on board and if there are any mandatory procedures that must be followed.",
  "An adult must be at least 12 years old.":
    "An adult must be at least 12 years old. Please enter valid DOB or relaunch your search considering this passenger as a child.",
    "A child must be 1 to 17 years old.":
    "A child must be 1 to 17 years old. Please enter valid DOB or relaunch your search considering this passenger as an adult.",
  "A child must be 1 to 17 years old. This passenger":
    "A child must be 1 to 17 years old. This passenger is considered as an infant that will turn 2 years old while en route. For that reason, carrier has special policy. Please contact us to make booking.",
  "A child must be 1 to  17 years old. Please relaunch":
    "A child must be 1 to  17 years old. Please relaunch your search considering this passenger as an infant.",
  "This passenger is considered as an infants":
    "This passenger is considered as an infant that will turn 1 year old while en route. For that reason, carrier has special policy. Please contact us to make booking.",
  "A infant must be less than 1 year old.":
    "A infant must be less than 1 year old. Please relaunch your search considering this passenger as an Child.",
  "An adult must be at least 18 years old.":
    "An adult must be at least 18 years old. Please enter valid DOB or relaunch your search considering this passenger as a child.",
  "Please scroll up to review":
    "Please scroll up to review the error messages and update the data accordingly.",
  "Payment for prepaid seats was not attempted.":"Payment for prepaid seats was not attempted.",  
  "Please select the": "Please select the above checkbox to proceed.",
  "Unable to display the details.":
    "Unable to display the details. No flight segment data and PQ information is available in the PNR",
  "Agency Code & Name": "Agency Code & Name",
  "Travel Agent Name": "Travel Agent Name",
  "Personal Username": "Personal Username",
  "Emergency Contact Phone Number": "Emergency Contact Phone Number",
  "id :": "id :",
  "PNR is Cancelled. Cannot Reprice.": "PNR is Cancelled. Cannot Reprice.",
  "Please note that the fare.":
    "Please note that the fare below will be recalculated at the time of booking.",
  "This is a last minute travel departing.":
    "This is a last minute travel request departing within 4 hours. Please contact us to proceed with your booking and have the ticket(s) issued in time.",
  "Segments data is not available": "Segments data is not available",
  "Your Booking is": "Your Booking is",
  "Your file will be on hold until": "Your file will be on hold until",
  "Eastern Time.Only a payment made":
    "Eastern Time.Only a payment made same day as booked and before ticketing time limit guarantees this fare. Beyond that, TravelBrands won't be responsible for any change in the fares, taxes or surcharges that may occur which are beyond our control.",
  "Credit cards will be charged":
    "Credit cards will be charged only if TravelBrands and/or your agency fees apply.",
  "Repricing a PNR that is paid or ticketed is not allowed.":
    "Repricing a PNR that is paid or ticketed is not allowed.",
  "Unable to fetch existing fare details from the PNR.":
    "Unable to fetch existing fare details from the PNR.",
  "Payable amount equals to selling amount + Merchant Fees when applicable":
    "Payable amount equals to selling amount + Merchant Fees when applicable",
  "Payable amount equals to Published Base Fare + Taxes and Fees + Markup Applied and Merchant Fees when applicable":
    "Payable amount equals to Published Base Fare + Taxes and Fees + Markup Applied and Merchant Fees when applicable",
  "Payable amount equals to Net Base Fare + Taxes and Fees":
    "Payable amount equals to Net Base Fare + Taxes and Fees",
  "The option and/or ticketing deadline for the existing fare on this PNR has expired.":
    "The option and/or ticketing deadline for the existing fare on this PNR has expired.",
  "Unable to price the PNR, please verify PNR record number.": "Unable to price the PNR, please verify PNR record number.",
  "Remark with your Intair code 5**PNR Owner-CXXXX**  is required in the PNR, please add and submit your PNR again.": "Remark with your Intair code 5**PNR Owner-CXXXX** is required in the PNR, please add and submit your PNR again.",
  "Your itinerary could not get priced because one of the flights came back": "Your itinerary could not get priced because one of the flights came back",
  "please try again later or book alternative flights.": "please try again later or book alternative flights.",
  "Fare no longer saved in the PNR": "Fare no longer saved in the PNR.",

  //  Error Messages From Api
  "Quote is saved": "Quote is saved",
  "Quote is deleted successfully": "Quote is deleted successfully",
  "Passport information is mandatory to apply payment and issue tickets.": "Passport information mandatory at time of booking.",
  "This pnr does not belong to" : "This pnr does not belong to ",
  "agent" : " agent ",
  ". Please verify travel agency and agent name field." : ". Please verify travel agency and agent name field.",
  "AIRLINE REFERENCE" : "AIRLINE REFERENCE ",
  "Regular Refund payment" : "Regular Refund payment",
  "Value must be" : "Value must be ",
  "or lower" : " or lower.",
  "Last 7 Days" : "Last 7 Days",
  "Last 4 Weeks" : "Last 4 Weeks",
  "Last 6 Months" : "Last 6 Months",
  "Last 12 Months" : "Last 12 Months",
  "Selected Itinenary Time Expired":"Selected itinerary booking window has expired. Please make a new search.",
  "Fare Already Booked":"Unable to book this fare  because you have already tried to book once. Please do a new search.",
  "Owner-CXXXX** is required in the PNR":"PNR OWNER remark is incorrectly added in PNR, Update this remark in your pnr exactly as shown: 5**PNR OWNER-CXXXX** etc.",

  //Duffle
  "Number of available seats and free bags":"Number of available seats and free bags, viewing seat map and making seat selection, fare rules and penalties on search results page, booking and confirmation pages.",
  "Number of free bags and cost":"Number of free bags and other available amenities are shown on branded fares page.",
  "Passenger gender, date of birth and contact details":"Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards.",
  "The file number is the airline record locator number.":"The file number is the airline record locator number.",
  "Special request, frequent traveler and passport information cannot yet be done through GIGI.":"Special request, frequent traveler and passport information cannot yet be done through GIGI.",
  "Booking on hold is not yet allowed":"Booking on hold is not yet allowed",
  "Payment - TBA":"Payment - TBA",
  "GIGI booking confirmation email will be automatically sent":"GIGI booking confirmation and electronic ticket emails are automatically sent",
  "GIGI electronic ticket email  – TBA":"GIGI electronic ticket email  – TBA",
  "Invoicing is done manually and may take up to 48 hours to be sent.":"Invoicing is done manually and may take up to 48 hours to be sent.",
  "Contact us to cancel your booking.":"Contact Intair to cancel your booking",
  "By selecting this fare, the booking will be done":"By selecting this fare, the booking will be done directly with Air Canada. You will be able to access this booking by going to ",
  "www.aircanada.com":"www.aircanada.com ",
  "and":"and",
  "view detailed baggage allowances.":"a. view detailed baggage allowances.",
  "add and change some travel options.":"b. add and change some travel options.",
  "view seat map, make and pay seat selection":"c. view seat map, make and pay seat selection",
  "link your passenger’s frequent flyer number.":"d. link your passenger’s frequent flyer number.",
  "Print and email the itinerary with seat":"e. Print and email the itinerary with seat and ticket numbers, aircraft type, detailed bag information etc.",
  "Instant purchase mandatory; only one credit card number is allowed":"Only same payment method for all the travelers is allowed and pax credit card billing address is required",
  "Only same payment allowed": "Only same payment method for all the travelers is allowed.",
  "Instant Purchase" : "Instant Purchase",
  "The carrier could not confirm this fare due to lack of booking class availability." : "The carrier could not confirm this fare due to lack of booking class availability. Please make a new search request.",
  "Value should be between" : "Value should be between ",
  "or lower" : " or lower.",
  "By Card" : "By Card ",
  "Card expiry date" : "Card expiry date ",
  "Passenger Name couldn't":"Passenger name no longer matches with secure flights Information. Please reconfirm and save date of birth and gender again",
  "Frequent Traveler, Redress" : "Redress",
  "The Price Summary details and Payment":"The Price Summary details and Payment of this file are no longer available online; please contact us.",
  "We were unable to complete booking":"We were unable to complete booking. One or more booking classes are no longer available. Please try a new search.",
  "Flight number(s)" : "Flight number(s)",
  "Wrong input, please follow AC 843, LH 1912" : "Wrong input, please refer the following E.g. AC 843, LH 1912",
  "Wrong input because of ',' after airline code" : "Wrong input because of ',' after airline code ",
  "Wrong input because" : "Wrong input because",
  "is missing the preceding 2 letter airline code." : " is missing the preceding 2 letter airline code.",
  "Wrong input, please follow 843, 1912" : "Wrong input, please follow 843, 1912",
  "Add multiple flight numbers separated by a comma. E.g AC 843, LH 1912" : "Add multiple flight numbers separated by a comma. E.g AC 843, LH 1912  or  843, 1912",
  "Redress, Passport Info" : "Redress, Passport Info",
  "Invalid Phone Number":"Booking failed due to an invalid phone number. Please make sure you have entered a valid phone number.",
  "fare will be recalculated with your markup and price summary updated accordingly. If you agree with new fare, you need to click on Save Markup button in order to save the fare in your file.":"Fare will be recalculated with your markup and price summary updated accordingly. If you agree with new fare, you need to click on Save Markup button in order to save the fare in your file.",
  "Applicable commission on refundable tickets will be recalled":"Applicable commission on refundable tickets will be recalled, and must be paid in full, to complete the refund and invoice.",
  "Apply": "Apply",
  "Add/Modify Agency Markup":" Add/Modify Agency Markup",
  "Save Changes":"Save Changes",
  "Discard Changes":"Discard Changes",
  "Do you want to save the changes made to":"Do you want to save the changes made to ",
  "Add/Modify Agency Markup and Modify Intair markup and fees":" Add/Modify Agency Markup and Modify Intair markup and fees",
  "Go Back":"Go Back",
  "You have unsaved changes in the":"You have unsaved changes in the ",
  "Refunded":"Refunded",
  "Please Note that the taxes were recalculated":
    "Please Note that the taxes were recalculated",
  "Please wait while we are updating the markup.": "Please wait while we are updating the markup...",
  "Success": "Success!",
  "Markup Successfully Updated": "Markup Successfully Updated!",
  "Please wait while we fetch the latest fare." : "Please wait while we retrieve the fare details.",
  "GIGI attempted to add your new additional markup":"GIGI attempted to add your new additional markup but the carrier has increased the fare currenlty confirmed. Contact us to have your markup added manually to the current fare to avoid the carrier’s increase.",
  "Please enter valid passport number" : "Please enter valid passport number",
  "Identical names are not permitted to be booked in the same reservation." : "Identical names are not permitted to be booked in the same reservation.",
  "PNR was booked in a PCC that doesn’t allow it to be priced using the Calculator":"The carrier requires the itinerary to be booked directly through Intair. Please use Gigi to search and book.",
  "A ticketed PNR cannot be repriced using the Calculator":"A ticketed PNR cannot be repriced using the Calculator.",
  "A paid PNR cannot be repriced using the Calculator":"A paid PNR cannot be repriced using the Calculator.",
  "Prepaid Seat document number(s) will be added in the seat selection details when issuance is completed." : "Prepaid Seat document number(s) will be added in the seat selection details when issuance is completed.",
  "The fare options selected do not apply to the cabin type":" The fare options selected do not apply to the cabin type that is currently booked. Review and change booking classes and cabin, then retry.",
  "There is currently no price with this validating carrier for this itinerary.":"There is currently no price with this validating carrier for this itinerary.",
  "This combination of different fares options per passenger cannot be priced":"This combination of different fares options per passenger cannot be priced on same PNR because the booking classes differ per passenger.",
  "Invalid Card!" : "Invalid Card!",
  "card isn’t allowed" : " card isn’t allowed",
  "Segment status is other than HK" : "The itinerary of this file has non-existent or unconfirmed segments",
  "Intair does not have the ticketing authority to issue tickets with this carrier.":"Intair does not have the ticketing authority to issue tickets with this carrier.",
  "There is no price applicable to the booking classes":"There is no price applicable to the booking classes and cabin that are currently booked. Review and change booking classes and cabin, then retry.",
  "Thank you. Your payment has been saved in your file and tickets will be issued. Click on the file number to view the ticketing status." : "Thank you. Your payment has been saved in your file and tickets will be issued. Click on the file number to view the ticketing status.",
  "Enter  the file number to price the itinerarry at the lowest available fare.":"Enter  the file number to price the itinerarry at the lowest available fare.",
  "Want to reprice with specific fare options?":"Want to reprice with specific fare options? Select from the drop-down menu or type in the carrier’s fare option code",
  "Want to see fares from an alternate validating carrier?":"Want to see fares from an alternate validating carrier?",
  "Fare options selection will become available once the itinerary is repriced":"Fare options selection will become available once the itinerary is repriced at the lowest available fare with the selected validating carrier.",
  "You have selected only child passenger type.":"You have selected only child passenger type. Child passenger must be accompanied by an adult. You are liable to ensure that this is the case and cross reference both files.",
  "Exclusive Flight Deals":"Exclusive Flight Deals",
  "Exclusive Flight Deals Ref" : "Exclusive Flight Deals Ref",
  "Select a filter to narrow down the available options." : "Select a filter to narrow down the available options.",
  "Departure Date" : "Departure Date",
  "View Fare Rules" : "View Fare Rules",
  "First class" : "First class",
  "Premium Econonmy" : "Premium Econonmy",
  "Agency Name" :"Agency Name",
  "Confirmation Number" : "Confirmation Number",
  "Passenger" : "Passenger",
  "Number of Passengers" : "Number of Passengers",
  "Price PNR":"Price PNR",
  "Select passenger type against each passenger to continue pricing the PNR":"Select passenger type against each passenger to continue pricing the PNR",
  "Lowest available fares":"Lowest available fares",
  "Enter the file number to price the itinerary at the lowest available fare.":"Enter the file number to price the itinerary at the lowest available fare.",
  "Clear All":"Clear All",
  "Select an option":"Select an option",
  "GIGI attempted to save this fare and received a warning that the child":"GIGI attempted to save this fare and received a warning that the child must be booked in a separate file due to different booking classes required.  Please contact us to divide your file.",
  "Other":"Other",
  "Partial EMD issued. Contact customer service for status of remaining EMDs.":"Partial EMD issued. Contact customer service for status of remaining EMDs.",
  "EMD issuance failed. Our ticketing agents will manually issue EMDs.":"EMD issuance failed. Our ticketing agents will manually issue EMDs.",
  "E-TicketNumber": "E-TicketNumber",
  "Payment Successful!" : "Payment Successful!",
  "File Number" : "File Number",
  "Day":"Day",
  "Month":"Month",
  "Year":"Year",
  "Years":"Years",
  "It can’t be a future date.":"Infant date of birth must be prior to booking date.",
  "Please Select":"Please Select",
  "Passenger: Electronic ticket":"Passenger: Electronic ticket",
  "Passenger: Itinerary only":"Passenger: Itinerary only",
  "Agency: Electronic ticket":"Agency: Electronic ticket",
  "Agency: Net Fare and Itinerary":"Agency: Net Fare and Itinerary",
  "Save Markup" : "Save Markup",
  "We are sorry, payment cannot be applied to this booking due to a technical issue. Please contact us for assistance.":"We are sorry, payment cannot be applied to this booking due to a technical issue. Please contact us for assistance.",
  "We are unable to combine the fare options selected for this multi-city itinerary. Please contact us." : "We are unable to combine the fare options selected for this multi-city itinerary. Please contact us.",
  "Total for":"Total for",
  "Total Base Fare": "Total base fare",
  "Total Airline taxes & fees": "Total airline taxes & fees",
  "Total Service fee":"Total service fee",
  "Total Ticketing fee":"Total ticketing fee",
  "Total Markup Applied":"Total markup applied",
  "Total all passengers":"Total all passengers",
  "Generate Invoice":"Generate Invoice",
  "Successfully generated the invoice on Travcom.":"Successfully generated the invoice on Travcom.",
  "Failed to generate the invoice on Travcom, please try again.":"Failed to generate the invoice on Travcom, please try again.",
  "Your file shows that tickets have been issued by the carrier":"Your file shows that tickets have been issued by the carrier and the refund request is immediately sent to the carrier. By clicking yes, you confirmed having read the below information.",
  "Loyalty points awarded will be adjusted accordingly.":"Loyalty points awarded will be adjusted accordingly.",
  "If applicable, Travelbrands service fees + taxes will be charged.":"If applicable, Travelbrands service fees + taxes will be charged.",
  "Your commission in full needs to be recalled. Send an email to aircommissionrecall@travelbrands.com":"Your commission in full needs to be recalled. Send an email to aircommissionrecall@travelbrands.com ",
  "If you wish for Intair to charge your own fees and include your commission recall.":"if you wish for Intair to charge your own fees and include your commission recall.",
  "All transactions are processed in the original form of payment.":"All transactions are processed in the original form of payment.",
  "Thank you. The itinerary for file XXXXXX has been canceled and your refund request sent to the carrier.":"Thank you. The itinerary for file XXXXXX has been canceled and your refund request sent to the carrier.",
  "Please allow a minimum of three weeks for the refund to be processed and credited to the original form of payment.":"Please allow a minimum of three weeks for the refund to be processed and credited to the original form of payment.",
  "You will receive an updated invoice once the carrier has confirmed that the refund has been processed.":"You will receive an updated invoice once the carrier has confirmed that the refund has been processed.",

  "Base fare and total taxes per passenger adult, child and infant is not available.  Only the total for the whole party traveling is showing." : "Base fare and total taxes per passenger adult, child and infant is not available.  Only the total for the whole party traveling is showing.",
  "Number of open seats to book is not obtainable." : "Number of open seats to book is not obtainable.",
  "Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards through Gigi." : "Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards through Gigi.",
  "Only the same payment method for all the travelers is allowed and passenger credit card billing address is required." : "Only the same payment method for all the travelers is allowed and passenger credit card billing address is required.",
  "Gigi file number is the airline record locator number." : "Gigi file number is the airline record locator number.",
  "GIGI booking confirmation and electronic ticket emails are automatically sent." : "GIGI booking confirmation and electronic ticket emails are automatically sent.",
  "Invoicing is done manually and may take up to 48 hours to be sent." : "Invoicing is done manually and may take up to 48 hours to be sent.",
  "Contact Intair to cancel your booking." : "Contact Intair to cancel your booking.",
  "By selecting this fare, the booking will be done directly with the carrier. You will be able to access this booking by going on the carrier’s website and use the options that are provided such as:" : "By selecting this fare, the booking will be done directly with the carrier. You will be able to access this booking by going on the carrier’s website and use the options that are provided such as:",
  "View baggage allowances and information about the fare option  booked." : "● View baggage allowances and information about the fare option  booked.",
  "Add and change some travel options." : "● Add and change some travel options.",
  "Do some special requests, add frequent traveler and passport information." : "● Do some special requests, add frequent traveler and passport information.",
  "View seat map, make and pay seat selection." : "● View seat map, make and pay seat selection.",
  "Print and email the itinerary or electronic ticket." : "● Print and email the itinerary or electronic ticket.",
  "Web fare booking is currently not permitted for same day departure.":"Booking Web fares for same day departure is currently not permitted.",
  "We have received your cancellation request.":"We have received your cancellation request. An email has been sent to our air service urgent team who will cancel this file for you. Once booking is cancelled, the booking status will show “cancelled” in Gigi.",
  "Update Booking Status":"Update Booking Status",
  "This button will update the booking status to show “Ticketed PNR Cancelled.”":"This button will update the booking status to show “Ticketed PNR Cancelled.” ",
  "You must first cancel this booking through aircanada.com\travel agent portal.":"You must first cancel this booking through aircanada.com\travel agent portal. ",
  "You then come back to GiGi to click on this button.":"You then come back to GiGi to click on this button.",
  "Is this booking cancelled with Air Canada?":"Is this booking cancelled with Air Canada?",
  "The booking class along with all the fare option details and rules are shown on " : "The booking class along with all the fare option details and rules are shown on ",
  "Thank you for submitting your payment." : "Thank you for submitting your payment.",
  "We are redirecting your payment information to the carrier." : "We are redirecting your payment information to the carrier. The check or credit card authorization for the total amount of published fare + taxes is processed by the carrier. Click on the file number to view the booking status.",
  "If payment with the carrier is successful, the booking status will" : `If payment with the carrier is successful, the booking status will show as "paid-ticketed".`,
  "If payment with the carrier is unsuccessful, the booking status will" : `If payment with the carrier is unsuccessful, the booking status will show "contact us"`,
  "Are you sure you want to change the status of this booking to" : "Are you sure you want to change the status of this booking to ",
  "Paid- Ticketed" : "Paid- Ticketed",
  "Confirmed – payment required" : "Confirmed – payment required",
  "Contact Us" : "Contact Us",
  "This button updates the booking status when a transaction was done through AC Call Centre or AC travel agent website and GiGi did not synchronize. You must first process the transaction with AC and then come back to GiGi to verify booking status and update it if need be." : "This button updates the booking status when a transaction was done through AC Call Centre or AC travel agent website and GiGi did not synchronize. You must first process the transaction with AC and then come back to GiGi to verify booking status and update it if need be.",
  "Agent or agency is not allowed to access any bookings.":"Agent or agency is not allowed to access any bookings.",
  "Agent or agency is not allowed to make bookings.":"Agent or agency is not allowed to make bookings.",
  "Please note that the taxes were recalculated. Do you want to proceed with payment?": "Please note that the taxes were recalculated. Do you want to proceed with payment?",
  "Your session has expired due to inactivity or a token expiration. Please log in again to continue." : "Your session has expired due to inactivity or a token expiration. Please log in again to continue.",
  "Restriction may apply. Please contact the carrier": "Restriction may apply. Please contact the carrier",
  "Please enter a valid DOB in the format DDMMMYY, e.g., 12AUG90": "Please enter a valid DOB in the format DDMMMYY, e.g., 12AUG90",
  "Invalid dates: birthdate is after departure date": "Invalid dates: birthdate is after departure date",
  "Please wait while GiGi is retrieving your booking data.":"Please wait while GiGi is retrieving your booking data.",
  "GiGi is verifying the taxes and updating the values in your price summmary.":"GiGi is verifying the taxes and updating the values in your price summmary.",
  "An error occured while saving the fare, please try again":"An error occured while saving the fare, please try again.",
  "Please wait while we get the latest fare." : "Please wait while we get the latest fare.",
  "Please wait while we save the new fare." : "Please wait while we save the new fare.",
  "Technical error occurred while saving the new fare. You will be redirected to Calculator." : "Technical error occurred while saving the new fare. You will be redirected to Calculator.",
  "Please select a fare option for outbound and inbound.":"Please select a fare option for outbound and inbound."
};
