import React, { useState } from "react";
import moment from "moment";
import { diffrenceInDatesCheck, formatNextDayTime ,splitFromCapital} from "../../utils/utility/commenFunctions";
import {get} from "lodash";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import { getCabinName } from "../../utils/utility/getCabinName";
import { useSelector } from "react-redux";
import DuffleClassOfServicePopUp from "../common/duffleClassOfServicePopUp";
function timeConvert(n) {
  let number = n;
  let hours = number / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return (number = rhours + "h " + rminutes + "m");
}

function FlightDetailsTab(props) {

  const [duffleClassOfServicePopUp, setDuffleClassOfServicePopUp] = useState(false);
  let fareBasisDetails = props.paxFare &&
    props.paxFare[0] &&
    props.paxFare[0].fareBasisDetails

  
  let segmentData =  props.data && Object.values(props.data)[0] && Object.values(props.data)[0]
  let layovers =segmentData && segmentData.layovers;
  let flights =segmentData && segmentData.flights;
  let flightDuration = segmentData && segmentData.durationMinutes;
  let cabinName = props?.finalCabinName ? props?.finalCabinName : getCabinName(flights);
  let cabinInfoForBookingPage = segmentData && segmentData?.classOfService;
  
  let layoverTime = 0;
  let flightTime = 0;
  const isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  const { t } = useTranslation();
  return (
    <>
      {flights &&
        flights.map((flight, index) => {
         
          let arrivalDate = flight.arrivalDateTime
          let dapartureDate = flight.departureDateTime
          let diffrenceInDates = diffrenceInDatesCheck(dapartureDate,arrivalDate); 
          let cabinVal;
          let newFlightDetail=fareBasisDetails && fareBasisDetails.filter(flightId => flightId.flightId === flight.id);
          let newCabinVal = newFlightDetail && newFlightDetail[0] && newFlightDetail[0].cabin;
          
          
          if(newCabinVal === undefined){
            if (props.flowFrom == "towSearchResult") {
              let details = fareBasisDetails && fareBasisDetails.find(flightId=> flightId.flightId === flight.id)
              cabinVal = details && details.cabin;
            } else {
              let cabinInfo = cabinInfoForBookingPage && cabinInfoForBookingPage.filter(flightId => flightId.flightId === flight.id)
              if(cabinInfo && cabinInfo.length > 0){
                cabinVal = cabinInfo && cabinInfo[0] && cabinInfo[0].cabin;
                cabinName = getCabinName(cabinInfoForBookingPage);
              }
            }
          }
    
          let brandedFareDetails = fareBasisDetails && fareBasisDetails.filter(flightId => flightId.flightId === flight.id)         
          let brandedFareName = newFlightDetail &&
            newFlightDetail[0] &&
            newFlightDetail[0].brand &&
            newFlightDetail[0].brand.brandName
          let brandedFareCode = newFlightDetail &&
            newFlightDetail[0] &&
            newFlightDetail[0].brand &&
            newFlightDetail[0].brand.code
          let num = timeConvert(flight.durationMin);
          let integerValue = parseInt(flight.durationMin);
          flightTime += integerValue
          
          let depdateTime = moment.utc(flight.departureDateTime).format("HH:mm");
          let classOfService = brandedFareDetails && brandedFareDetails[0] && brandedFareDetails[0].classOfService;
          let aircraftDescription = flight && flight.aircraftDescription;
          let aircraftType = flight && flight.aircraftType;
          let aircraftToDisplay = aircraftDescription !== undefined ? aircraftDescription : aircraftType;
          let operatingCarrierName = flight && flight.operatingCarrierName;
          let operatingCarrierNameToDisplay = operatingCarrierName !== undefined ? operatingCarrierName : "";
          // STOP OVER STARTED
          let stopOverDetails = flight && flight.technicalStops && flight.technicalStops[0]
         
        
          let stopOverAirport = stopOverDetails && stopOverDetails.airport;
          let stopOverDuration = stopOverDetails && stopOverDetails.durationMin;
        // STOP OVER END
          return (
            <div key={flight.id}>

              <div className="flight_tabs_hader">
                <div className="row head">
                  <div className="flight_tabs-left">
                    <div className="text_title">
                      <span>
                        
                        {flight.carrierName !== undefined ? flight.carrierName : flight.carrierName}{" "} 
                        {flight.carrier} {flight.number} {props?.flowFrom == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>setDuffleClassOfServicePopUp(true)}></i>: classOfService}
                      </span>
                      {newCabinVal == undefined ?
                        <>
                        {cabinVal && (props?.flowFrom == 'confirmation' ? !isDuffleFlow : true) && 
                        <span className={(cabinName === "Mixed" || cabinName === "Mixed Cabin") ? "flightDetails-cabinType" : ""}>
                        (
                        {cabinVal === "PremiumEconomy" ? t("PremiumEconomy") : t(cabinVal)}
                        )
                       </span>
                       }
                      </>
                      :
                      <>{(props?.flowFrom == 'confirmation' ? !isDuffleFlow : true) && (<span className={(cabinName === "Mixed" || cabinName === "Mixed Cabin") ? "flightDetails-cabinType" : ""}>
                        (
                        {newCabinVal === "PremiumEconomy" ? t("PremiumEconomy") : t(newCabinVal)}
                        )
                      </span>)}</>
                      }
                    </div>
                  </div>
                  <div className="flight_tabs-right">


                   
                    
                  {brandedFareCode !== undefined ?
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      delay={{ show: 100 }}
                      overlay={
                        <Popover className="seatView-popover">
                        <div className="seatView-popover-row seatView-popover-amount">
                        {t("Brand Code :")} {brandedFareCode}
                          </div>
                        </Popover>
                      }
                    >
                      {brandedFareName !== undefined && <div className="fareOption">{t("Fare Option")} {brandedFareName} </div>}
                    </OverlayTrigger> : brandedFareName !== undefined && <div className="fareOption">{t("Fare Option")} {brandedFareName}</div>}


                  </div>
                </div>
                <div className="row flight_tabs-side">

                   <span>
                      {" "}
                      {flight.operatingCarrier && t("Operated by:") + operatingCarrierNameToDisplay + " (" + flight.operatingCarrier + ")"} 
                      {aircraftToDisplay ? " | " + aircraftToDisplay : ""}
                    {flight && flight.aircraftNoOfSeats &&
                      <span>{" | "+flight.aircraftNoOfSeats + t("Seats")}</span>
                    }
                    {flight && flight.airlineRecordLocator &&
                      <span>{" | " + t("AIRLINE REFERENCE") +flight.airlineRecordLocator }</span>
                    }
                    </span>

                </div>
              </div>



              <div className="f-details-senction">
                <div className="f-details-col">
                  <div className="fd-name-time">
                    {flight && flight.departureAirportCity} ({flight && flight.departureAirport})<br />
                    <span>{depdateTime}</span>
                  </div>
                  {flight.departureTerminal !== undefined ? (
                    <span className="fd-aireport-detail">
                      {t("Terminal") + flight.departureTerminal}
                    </span>
                  ) : (
                    <span className="fd-aireport-detail">
                      {t("Terminal") + " -"}
                    </span>
                  )}
                </div>
                <div className="fd-time">
                  {num} <i className="far fa-clock"></i>
                </div>
                <div className="f-details-col">
                  <div className="fd-name-time">
                    {flight && flight.arrivalAirportCity} ({flight && flight.arrivalAirport})<br />
                    {/* <span>{formatNextDayTime(flight.departureDateTime, flight.arrivalDateTime)}</span> */}
                    {diffrenceInDates !== 0 ? <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")} <sup className="text-danger">{Number(diffrenceInDates) > 0 ? "+" + diffrenceInDates : diffrenceInDates}</sup> </span> : <span> {moment.utc(flight.arrivalDateTime).format("HH:mm")}</span>}
                  </div>
                  {flight && flight.arrivalTerminal !== undefined ? (
                    <span className="fd-aireport-detail">
                      {t("Terminal") + flight.arrivalTerminal}
                    </span>
                  ) : (
                    <span className="fd-aireport-detail">
                      {t("Terminal") + " -"}
                    </span>
                  )}
                </div>
              </div>
              {layovers && layovers[index] ? (
                <>
                {stopOverAirport ?
                <div className="f-DetailsLayover">
                  <div className="f-DetailsLayoverTitle">

                    <span className="f-DetailsLayoverTym">
                      
                      {timeConvert(stopOverDuration)}{" "} 
                      <span className="bd-duration-text">Technical Stopover in {stopOverAirport}</span> 
                      
                      <div> + </div>
                      <div>
                      {timeConvert(layovers[index].durationMinutes)}{" "}
                      <span className="bd-duration-text">Layover in  {flight.arrivalAirport}</span>
                      </div>
                    </span>
                    
                  </div>
                </div>
                :
                <div className="f-DetailsLayover">
                  <div className="f-DetailsLayoverTitle">

                    <span className="f-DetailsLayoverTym">
                      {timeConvert(layovers[index].durationMinutes)}{" "}
                    </span>
                    {t("Layover in") + flight.arrivalAirport}
                  </div>
                </div>
                }
                </>
              ) : 
              <>
              {stopOverAirport ?
              <div className="f-DetailsLayover">
              <div className="f-DetailsLayoverTitle">
                <span className="f-DetailsLayoverTym">
                {timeConvert(stopOverDuration)}{" "}                
                </span>
                Technical Stopover in {stopOverAirport}
              </div>
              </div> 
              : 
              null            
              }             
              </>
              }
              

              {flights && flights.length - 1 === index ? (
                <div className="fd-total-time">
                  <div>
                    {t("Total Flight Time")}
                    <span>
                      <i className="far fa-clock"></i>
                      {timeConvert(flightTime)}
                    </span>
                  </div>
                  {layovers && layovers.map(value => {
                    layoverTime += value.durationMinutes
                  })}
                  <div>
                    {t("Total Trip Duration")}
                    <span>
                      <i className="far fa-clock"></i>{timeConvert(flightDuration)}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
        <DuffleClassOfServicePopUp open={duffleClassOfServicePopUp} handleClose={()=>setDuffleClassOfServicePopUp(false)}/>
    </>
  );
}

export default FlightDetailsTab;
